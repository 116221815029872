const criminal_revision_hc_mamla_urls = {
    get_by_file: "mamla/loanmamla/criminal_revision_hc_by_file/{fileid}/{seq}/",
    get_by_parent: "mamla/loanmamla/criminal_revision_hc_by_parent/{parent}/{seq}/",
    list: "mamla/loanmamla/criminal_revision_hc/",
    blank: "mamla/loanmamla/criminal_revision_hc_blank/",
    disposal: "mamla/loanmamla/criminal_revision_hc_disposal/",
    single: "mamla/loanmamla/criminal_revision_hc/{id}/",
    add: "mamla/loanmamla/criminal_revision_hc/"
}

export default criminal_revision_hc_mamla_urls;
<template>
  <v-container class="ma-0 pa-0">
    <v-skeleton-loader
        type="table-heading, list-item-two-line,table-tfoot"
        class="mx-auto"
        v-bind="attrs"
        v-if="loader"
    >
    </v-skeleton-loader>
    <v-container v-if="!loader" class="pa-0 ma-0">
      <v-row align="center" justify="center" class="my-3" v-if="view_buttons">
        <v-col cols="6" align="center" justify="center">
          <AddDCCaseForm v-if="add_button" activator_name="Add New Mamla"
                         form_title="Add New"
                         :url="urls.add_url" :file="this.parent.file.id"
                         :parent="parent.id"
                         :seq=seq
                         :flow="flow"
                         activator_icon="mdi-plus" action="add"
                         tooltip_text="Add New"
                         big_btn="true" color="orange" @done_event="mamla_updated"
                         snackbar_text="New Civil Revision (DC) Mamla is added successfully"/>
        </v-col>
        <v-col cols="6" align="center" justify="center">
          <AddBlankDCCaseForm v-if="add_blank_button" @done_event="mamla_updated"
                              :file="this.parent.file.id" :parent="parent.id"
                              dialogue_text="Are you sure you, you want to create a blank entry?"
                              :url="urls.blank_url"
                              :seq=seq
                              :flow="flow"
                              activator_name="Create a blank Entry"
                              activator_icon="mdi-file-hidden"
                              tooltip_text="Add Blank Entry"
                              snackbar_text="Civil Revision (DC) Mamla has been created"
                              action="blank"
                              big_btn="true" color="orange" :small=false>
          </AddBlankDCCaseForm>
        </v-col>
      </v-row>
      <v-container v-if="!view_buttons" class="pa-0 ma-0">
        <MamlaDetails :info="info" banner_text="Civil Revision (DC)"/>
        <Actions :info="info" @update_event="update_event_handler" @delete_event="delete_event_hanlder"/>
        <HistoryViews :info="info"/>
        <slot name="civil_rv_dc_child" :parent="info" :seq="parseInt(seq)+1" :flow="flow">

        </slot>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import axios from "axios";
import AddBlankDCCaseForm
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/artho_apeal_dc/sub_components/AddBlankDCCaseForm";
import AddDCCaseForm
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/artho_apeal_dc/sub_components/AddDCCaseForm";
import Actions from "@/components/mamla/loan_mamla/loan_mamla_sub_components/civil_rv_dc/sub_components/Actions";
import HistoryViews from "@/components/mamla/loan_mamla/common_components/HistoryViews";
import MamlaDetails
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/artho_apeal_dc/sub_components/MamlaDetails";
import civil_rv_dc_mamla_perms from "@/constants/permissions/civil_rv_dc_perms";
import civil_rv_dc_mamla_urls from "@/constants/mamla/loan_mamla/civil_rv_dc";

export default {
  name: "CivilRvDc",
  props: ['parent', 'seq', 'flow'],
  components: {
    MamlaDetails,
    AddBlankDCCaseForm, Actions, HistoryViews, AddDCCaseForm
  },
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
    view_buttons() {
      return !this.loader && !this.info
    },
    add_button: function () {
      return this.$store.getters.permissionCheckers(civil_rv_dc_mamla_perms.add) && !this.loader && !this.info
    },
    add_blank_button: function () {
      return this.$store.getters.permissionCheckers(civil_rv_dc_mamla_perms.blank) && !this.loader && !this.info
    },
    view_details() {
      return !this.loader && this.info
    }
  },
  data: () => ({
    urls: {
      get_url: civil_rv_dc_mamla_urls.get_by_parent,
      blank_url: civil_rv_dc_mamla_urls.blank,
      add_url: civil_rv_dc_mamla_urls.add,
    },
    attrs: {
      class: 'mb-6',
      boilerplate: false,
      elevation: 2,
    },
    loader: true,
    info: {},
  }),
  methods: {
    get_info() {
      this.loader = true
      this.axios_conf
      let config = {
        headers: {
          multi: 'true'
        }
      }
      axios.get(this.urls.get_url.replace('{parent}', this.parent.id).replace('{seq}', this.seq), config).then((resp) => {
        this.$store.commit('setJWT', resp.data.key)
        this.info = resp.data.items[0]
        this.loader = false
      }).catch((error) => {
        this.$store.commit('setJWT', error.response.data.key)
        this.loader = false
        this.info = {}
      })
    },
    mamla_updated(value) {
      this.loader = false
      this.info = value
      this.$emit('update_signal', true)
    },
    update_event_handler(value) {
      this.info = value
    }
    ,
    delete_event_hanlder() {
      this.get_info()
    }
  },
  mounted() {
    this.get_info()
  },

}
</script>

<style scoped>

</style>
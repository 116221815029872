const cr_session_mamla_urls = {
    get_by_file: "mamla/loanmamla/cr_session_by_file/{fileid}/{seq}/",
    get_by_parent: "mamla/loanmamla/cr_session_by_parent/{parent}/{seq}/",
    list: "mamla/loanmamla/cr_session/",
    blank: "mamla/loanmamla/cr_session_blank/",
    disposal: "mamla/loanmamla/cr_session_disposal/",
    single: "mamla/loanmamla/cr_session/{id}/",
    delete: "mamla/loanmamla/cr_session/{id}/",
    add: "mamla/loanmamla/cr_session/",
}

export default cr_session_mamla_urls;
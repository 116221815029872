<template>
  <CriminalMiscCaseHc :parent="parent" :seq="seq" :flow="flow">
    <template v-slot:criminal_misc_case_hc_child="{parent, seq, flow}">
      <CrplaAdContainer :parent="parent" :seq="seq" :flow="flow" class="my-1"></CrplaAdContainer>
    </template>
  </CriminalMiscCaseHc>
</template>

<script>
import CriminalMiscCaseHc
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/criminal_misc_case_hc/CriminalMiscCaseHc";
import CrplaAdContainer from "@/components/mamla/loan_mamla/loan_mamla_sub_components/crpla/CrplaAdContainer";

export default {
  name: "Flow4",
  props: ['parent', 'seq', 'flow'],
  components: {CriminalMiscCaseHc, CrplaAdContainer}
}
</script>

<style scoped>

</style>
<template>
  <CivilRvDc :parent="parent" :seq="seq" :flow="flow">
    <template v-slot:civil_rv_dc_child="{parent,seq, flow}">
      <CivilRVContainer :parent="parent" :seq="seq" :flow="flow" class="my-1">

      </CivilRVContainer>
    </template>
  </CivilRvDc>
</template>

<script>

import CivilRvDc from "@/components/mamla/loan_mamla/loan_mamla_sub_components/civil_rv_dc/CivilRvDc";
import CivilRVContainer from "@/components/mamla/loan_mamla/loan_mamla_sub_components/civil_rv_hc/CivilRVContainer";

export default {
  name: "Flow5",
  props: ['parent', 'seq', 'flow'],
  components: {CivilRvDc, CivilRVContainer}
}
</script>

<style scoped>

</style>
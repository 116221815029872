<template>
  <v-dialog
      max-width="70%"
      v-model="dialog"
      @input="load_data"
  >

    <template v-slot:activator="{ on:dialog, attrs }">
      <v-btn v-if="big_btn" v-bind="attrs" v-on="{...dialog}" :loading="loading" :color="color" :small="small">
        <v-icon>
          {{ activator_icon }}
        </v-icon>
        {{ activator_name }}
      </v-btn>
      <v-tooltip bottom v-if="!big_btn">
        <template v-slot:activator="{on:tooltip}">
          <v-icon v-if="!big_btn" v-bind="attrs" small
                  v-on="{...dialog,...tooltip}" class="mx-4">
            {{ activator_icon }}
          </v-icon>
        </template>
        <span v-if="!big_btn">{{ tooltip_text }}</span>
      </v-tooltip>
    </template>

    <ValidationObserver ref="observer" v-slot="{ handleSubmit}">
      <v-form ref="vform" @submit.prevent="handleSubmit(handle_submit)">
        <v-card class="light-blue lighten-3">
          <v-card-title>
            <span class="text-h5">{{ form_title }}</span>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="non_field" name="None Field"
                                    rules=""
                                    v-slot="{ errors }">
                  <ul v-for="err in errors" :key="err">
                    <li>
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="4"
              >
                <ValidationProvider mode="eager" vid="cause" name="Cause"
                                    rules="objectNotEmpty:cause|required"
                                    v-slot="{ errors }">
                  <v-autocomplete
                      label="Cause*"
                      hint="Case of the case"
                      :items="causes"
                      :item-text="(item)=>item.cause"
                      item-value="code"
                      v-model="item.cause"
                      requiredkl
                      autocomplete="nope"
                      :loading="causes_loading"
                      dense
                  >
                  </v-autocomplete>
                  <ul>
                    <li v-for="err in errors" :key="err">
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="4"
              >
                <ValidationProvider mode="eager" vid="case_filed_by" name="Case Filed by"
                                    rules="objectNotEmpty:case_filed_by|required"
                                    v-slot="{ errors }">
                  <v-autocomplete
                      label="Case Filed By*"
                      hint="Whomever filed the case"
                      :items="filed_by"
                      :item-text="(item)=>item.name"
                      item-value="code"
                      v-model="item.case_filed_by"
                      requiredkl
                      autocomplete="nope"
                      :loading="filed_by_loading"
                      dense
                  >
                  </v-autocomplete>
                  <ul>
                    <li v-for="err in errors" :key="err">
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="4"
              >
                <ValidationProvider mode="eager" vid="case_number" name="Case Number"
                                    rules="required"
                                    v-slot="{ errors }">
                  <v-text-field
                      label="Case Number*"
                      hint="Case Number for the mamla"
                      required
                      autocomplete="nope"
                      v-model="item.case_number"
                      maxlength="256"
                      counter
                      dense
                  ></v-text-field>
                  <ul>
                    <li v-for="err in errors" :key="err">
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="4"
              >

                <v-menu
                    v-model="filing_date_menu"
                    :nudge-right="40"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    dense
                >
                  <template v-slot:activator="{ on, attrs }">
                    <ValidationProvider mode="eager" vid="filing_date" name="Filing Date"
                                        rules="required"
                                        v-slot="{ errors }">
                      <v-text-field
                          v-model="item.filing_date"
                          label="Filing Date*"
                          hint="Date on which case is filed on the court"
                          prepend-icon="mdi-calendar"
                          readonly
                          required
                          v-bind="attrs"
                          v-on="on"
                          dense
                      ></v-text-field>
                      <ul>
                        <li v-for="err in errors" :key="err">
                          <span class="red--text">{{ err }}</span>
                        </li>
                      </ul>
                    </ValidationProvider>
                  </template>
                  <v-date-picker
                      v-model="item.filing_date"
                      @input="filing_date_menu=false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                  cols="4"
              >
                <ValidationProvider mode="eager" vid="claimed_amnt" name="Claimed Amount"
                                    rules="required|min_value:1"
                                    v-slot="{ errors }">
                  <v-text-field
                      label="Claimed Amount*"
                      hint="Claimed Amount for this mamla"
                      required
                      autocomplete="nope"
                      v-model="item.claimed_amnt"
                      maxlength="32"
                      counter
                      clearable
                      dense
                      type="number"
                  ></v-text-field>
                  <ul>
                    <li v-for="err in errors" :key="err">
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="4"
              >
                <ValidationProvider mode="eager" vid="lawyer" name="Lawyer"
                                    rules="objectNotEmpty:lawyer|required"
                                    v-slot="{ errors }">
                  <v-autocomplete
                      label="Lawyer*"
                      hint="Search by lawyer name and mobile no"
                      :items="lawyer"
                      :item-text="(item)=>item.name + ' | ' + item.mobile_no"
                      item-value="id"
                      v-model="item.lawyer"
                      requiredkl
                      autocomplete="nope"
                      :loading="lawyer_loading"
                      dense
                  >
                  </v-autocomplete>
                  <ul>
                    <li v-for="err in errors" :key="err">
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="4"
              >

                <v-menu
                    v-model="lawyer_appointing_date_menu"
                    :nudge-right="40"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    dense
                >
                  <template v-slot:activator="{ on, attrs }">
                    <ValidationProvider mode="eager" vid="lawyer_appointing_date" name="Lawyer Appointing Date"
                                        rules="required"
                                        v-slot="{ errors }">
                      <v-text-field
                          v-model="item.lawyer_engage_date"
                          label="Date of appointing lawyer"
                          hint="Date of appointing lawyer"
                          prepend-icon="mdi-calendar"
                          readonly
                          required
                          v-bind="attrs"
                          v-on="on"
                          dense
                      ></v-text-field>
                      <ul>
                        <li v-for="err in errors" :key="err">
                          <span class="red--text">{{ err }}</span>
                        </li>
                      </ul>
                    </ValidationProvider>
                  </template>
                  <v-date-picker
                      v-model="item.lawyer_engage_date"
                      @input="lawyer_appointing_date_menu=false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                  cols="4"
              >
                <ValidationProvider mode="eager" vid="district" name="District"
                                    rules="objectNotEmpty:district|required"
                                    v-slot="{ errors }">
                  <v-autocomplete
                      label="District*"
                      hint="District"
                      :items="districts"
                      :item-text="(item)=>item.name"
                      item-value="id"
                      v-model="item.district"
                      requiredkl
                      autocomplete="nope"
                      :loading="district_loading"
                      dense
                  >
                  </v-autocomplete>
                  <ul>
                    <li v-for="err in errors" :key="err">
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="4"
              >
                <ValidationProvider mode="eager" vid="court_type" name="Court Type"
                                    rules="objectNotEmpty:court_type|required"
                                    v-slot="{ errors }">
                  <v-autocomplete
                      label="Court Type*"
                      hint="Search by court type name"
                      :items="court_types"
                      :item-text="(item)=>item.name"
                      item-value="id"
                      v-model="court_type"
                      required
                      autocomplete="nope"
                      :loading="court_types_loading"
                      dense
                  >
                  </v-autocomplete>
                  <ul>
                    <li v-for="err in errors" :key="err">
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="4"
              >
                <ValidationProvider mode="eager" vid="court" name="Court"
                                    rules="objectNotEmpty:court|required"
                                    v-slot="{ errors }">
                  <v-autocomplete
                      label="Court*"
                      hint="Search by court name"
                      :items="courts"
                      :item-text="(item)=>item.name"
                      item-value="id"
                      v-model="item.court"
                      required
                      autocomplete="nope"
                      :loading="courts_loading"
                      dense

                  >
                  </v-autocomplete>
                  <ul>
                    <li v-for="err in errors" :key="err">
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="4"
              >
                <ValidationProvider mode="eager" vid="court_no" name="Case No"
                                    rules="required"
                                    v-slot="{ errors }">
                  <v-text-field
                      label="Court No*"
                      hint="Court No"
                      required
                      autocomplete="nope"
                      v-model="item.court_no"
                      maxlength="256"
                      counter
                      dense
                      type="number"
                  ></v-text-field>
                  <ul>
                    <li v-for="err in errors" :key="err">
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="4"
              >

                <v-menu
                    v-model="next_date_menu"
                    :nudge-right="40"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    dense
                >
                  <template v-slot:activator="{ on, attrs }">
                    <ValidationProvider mode="eager" vid="next_date" name="Next Date"
                                        rules="required"
                                        v-slot="{ errors }">
                      <v-text-field
                          v-model="item.next_date"
                          label="Next Date*"
                          hint="Next court Date"
                          prepend-icon="mdi-calendar"
                          readonly
                          required
                          v-bind="attrs"
                          v-on="on"
                          dense
                          clearable
                      ></v-text-field>
                      <ul>
                        <li v-for="err in errors" :key="err">
                          <span class="red--text">{{ err }}</span>
                        </li>
                      </ul>
                    </ValidationProvider>
                  </template>
                  <v-date-picker
                      v-model="item.next_date"
                      @input="next_date_menu=false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                  cols="4"
              >
                <ValidationProvider mode="eager" vid="purpose" name="Purpose"
                                    rules="objectNotEmpty:purpose|required"
                                    v-slot="{ errors }">
                  <v-autocomplete
                      label="Purpose*"
                      hint="Purpose"
                      :items="purposes"
                      :item-text="(item)=>item.name"
                      item-value="id"
                      v-model="item.purpose"
                      required
                      autocomplete="nope"
                      :loading="purposes_loading"
                      dense
                  >
                  </v-autocomplete>
                  <ul>
                    <li v-for="err in errors" :key="err">
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="4"
              >
                <ValidationProvider mode="eager" vid="purpose_remarks" name="Purpose Remarks"
                                    v-slot="{ errors }">
                  <v-textarea
                      v-model="item.purpose_remarks"
                      label="Purpose Remarks*"
                      hint="Purpose Remarks"
                      dense
                      clearable
                      auto-grow
                  ></v-textarea>
                  <ul>
                    <li v-for="err in errors" :key="err">
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
            </v-row>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
            >
              Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                type="submit"
                :loading="submit_loading"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import axios from "axios";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import "@/validations/office_validations"
import personnel_urls from "@/constants/personnel_urls";
import app_setting_api from "@/constants/app_setting/app_setting_api";
import {user_urls} from "@/constants/user_urls";

export default {
  name: "AddDCCaseForm",
  props: ['icon', 'activator_name', 'activator_icon', 'form_title', 'action', 'big_btn', 'url', 'snackbar_text', 'tooltip_text', 'loading', 'color', 'small', 'file', 'parent', 'seq', 'flow'],
  components: {ValidationProvider, ValidationObserver},
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
    computed_passed_item: {
      get() {
        return JSON.parse(JSON.stringify(this.passed_item))
      },
    },
    computed_courts: {
      get(type) {
        console.log(this.courts.filter(court => court.type.id === type))
        return this.courts.filter(court => court.type.id === type)
      }
    }
  },
  data: () => ({
    submit_loading: false,
    dialog: false,
    filing_date_menu: false,
    next_date_menu: false,
    lawyer_appointing_date_menu: false,
    lawyer_loading: false,
    courts_loading: false,
    districts: [],
    district_loading: false,
    courts: [],
    court_types: [],
    court_type: [],
    court_types_loading: false,
    purposes: [],
    purposes_loading: false,
    causes: [],
    causes_loading: false,
    filed_by: [],
    filed_by_loading: false,
    item: {
      case_number: "",
      filing_date: "",
    },
    lawyer: [],
    from_date_menu: false,
    to_date_menu: false,
    non_field_errors: [],
  }),
  methods: {
    async add_new_item() {
      this.axios_conf
      let conf = {
        headers: {
          multi: true
        }
      }
      let data = {
        file: this.file,
        parent_mamla: this.parent,
        seq: this.seq,
        flow: this.flow,
        ...this.item
      }
      await axios.post(this.url, data, conf)
          .then((resp) => {
            this.$store.commit('setJWT', resp.data.key)
            this.dialog = false
            this.$refs.vform.resetValidation()
            this.$refs.vform.reset()
            this.$emit('done_event', resp.data.items)
            this.$store.commit("update_snackbar", {
              text: this.snackbar_text.replace('{value}', data.case_no),
              status: true
            })
          })
          .catch((error) => {
            // console.log(error.response.data)
            if (error.response.data) {
              this.$store.commit('setJWT', error.response.data.key)
              // console.log(error.response.data.key)
              this.$refs.observer.setErrors({
                case_number: error.response.data.errors.case_number,
                filing_date: error.response.data.errors.filing_date,
                claimed_amnt: error.response.data.errors.claimed_amnt,
                lawyer: error.response.data.errors.lawyer,
                lawyer_engage_date: error.response.data.errors.lawyer_engage_date,
                next_date: error.response.data.errors.next_date,
                purpose: error.response.data.errors.purpose,
                purpose_remarks: error.response.data.errors.purpose_remarks,
                district: error.response.data.errors.district,
                court: error.response.data.errors.court,
                court_no: error.response.data.errors.court_no,
                non_field: error.response.data.errors.non_field_errors,
                cause: error.response.data.errors.cause,
                case_filed_by: error.response.data.errors.case_filed_by,
              });
            }
          })
      this.submit_loading = false
    },
    handle_submit() {
      this.submit_loading = true
      this.add_new_item()
    },
    get_lawyer() {
      this.axios_conf
      let conf = {
        headers: {multi: true}
      }
      this.lawyer_loading = true
      axios.get(personnel_urls.lawyer.list, conf).then((resp) => {
        this.$store.commit('setJWT', resp.data.key)
        this.lawyer = resp.data.items
        this.lawyer_loading = false
      }).catch((err) => {
        if (err.response) {
          if (err.response.status >= 400) {
            this.axios_conf
            axios.post(user_urls.user)
            this.$router.replace({name: 'Login'})
            this.$store.commit('setLogout')
            return 0
          }
          this.$store.commit('setJWT', err.response.data.key)
        }
        this.$store.commit("update_snackbar", {text: "Unable to get lawyer data", status: true})
        this.$refs.observer.setErrors({
          lawyer: ["Unable to get lawyer data"],
        });
      })
    },
    get_districts() {
      this.axios_conf
      let conf = {
        headers: {multi: true}
      }
      this.district_loading = true
      axios.get(app_setting_api.district.list, conf).then((resp) => {
        this.$store.commit('setJWT', resp.data.key)
        this.districts = resp.data.items
        this.district_loading = false
      }).catch((err) => {
        if (err.response) {
          if (err.response.status >= 400) {
            this.axios_conf
            axios.post(user_urls.user)
            this.$router.replace({name: 'Login'})
            this.$store.commit('setLogout')
            return 0
          }
          this.$store.commit('setJWT', err.response.data.key)
        }
        this.$store.commit("update_snackbar", {text: "Unable to get district data", status: true})
        this.$refs.observer.setErrors({
          district: ["Unable to get district data, contact admin"],
        });
      })
    },
    get_court_types() {
      this.axios_conf
      let conf = {
        headers: {multi: true}
      }
      this.court_types_loading = true
      axios.get(app_setting_api.court_type.list, conf).then((resp) => {
        this.$store.commit('setJWT', resp.data.key)
        this.court_types = resp.data.items
        this.court_types_loading = false
      }).catch((err) => {
        if (err.response) {
          if (err.response.status >= 400) {
            this.axios_conf
            axios.post(user_urls.user)
            this.$router.replace({name: 'Login'})
            this.$store.commit('setLogout')
            return 0
          }
          this.$store.commit('setJWT', err.response.data.key)
        }
        this.$store.commit("update_snackbar", {text: "Unable to get Court data", status: true})
        this.$refs.observer.setErrors({
          court: ["Unable to get Court Type data, contact admin"],
        });
      })
    },
    get_courts(courtType) {
      this.axios_conf
      let conf = {
        headers: {multi: true}
      }
      this.courts = []
      this.courts_loading = true
      axios.get(app_setting_api.court.list, conf).then((resp) => {
        this.$store.commit('setJWT', resp.data.key)
        this.courts = resp.data.items.filter(value => value.type.id === courtType)
        this.courts_loading = false
      }).catch((err) => {
        if (err.response) {
          if (err.response.status >= 400) {
            this.axios_conf
            axios.post(user_urls.user)
            this.$router.replace({name: 'Login'})
            this.$store.commit('setLogout')
            return 0
          }
          this.$store.commit('setJWT', err.response.data.key)
        }
        this.$store.commit("update_snackbar", {text: "Unable to get Court data", status: true})
        this.$refs.observer.setErrors({
          court: ["Unable to get Court data, contact admin"],
        });
      })
    },
    get_purposes(courtType) {
      this.axios_conf
      let conf = {
        headers: {multi: true}
      }
      this.purposes = []
      this.purposes_loading = true
      axios.get(app_setting_api.purpose.list, conf).then((resp) => {
        this.$store.commit('setJWT', resp.data.key)
        this.purposes = resp.data.items.filter(v => v.court_type.id === courtType)
        this.purposes_loading = false
      }).catch((err) => {
        if (err.response) {
          if (err.response.status >= 400) {
            this.axios_conf
            axios.post(user_urls.user)
            this.$router.replace({name: 'Login'})
            this.$store.commit('setLogout')
            return 0
          }
          this.$store.commit('setJWT', err.response.data.key)
        }
        this.$store.commit("update_snackbar", {text: "Unable to get Court data", status: true})
        this.$refs.observer.setErrors({
          court: ["Unable to get purpose data, contact admin"],
        });
      })
    },
    get_causes() {
      this.axios_conf
      let conf = {
        headers: {multi: true}
      }
      this.causes_loading = true
      axios.get(app_setting_api.cause.list, conf).then((resp) => {
        this.$store.commit('setJWT', resp.data.key)
        this.causes = resp.data.items
        this.causes_loading = false
      }).catch((err) => {
        if (err.response) {
          if (err.response.status >= 400) {
            this.axios_conf
            axios.post(user_urls.user)
            this.$router.replace({name: 'Login'})
            this.$store.commit('setLogout')
            return 0
          }
          this.$store.commit('setJWT', err.response.data.key)
        }
        this.$store.commit("update_snackbar", {text: "Unable to get cause data", status: true})
        this.$refs.observer.setErrors({
          court: ["Unable to get Cause data, contact admin"],
        });
      })
    },
    get_filed_by() {
      this.axios_conf
      let conf = {
        headers: {multi: true}
      }
      this.filed_by_loading = true
      axios.get(app_setting_api.case_filed_by.list, conf).then((resp) => {
        this.$store.commit('setJWT', resp.data.key)
        this.filed_by = resp.data.items
        this.filed_by_loading = false
      }).catch((err) => {
        if (err.response) {
          if (err.response.status >= 400) {
            this.axios_conf
            axios.post(user_urls.user)
            this.$router.replace({name: 'Login'})
            this.$store.commit('setLogout')
            return 0
          }
          this.$store.commit('setJWT', err.response.data.key)
        }
        this.$store.commit("update_snackbar", {text: "Unable to get case filed by data", status: true})
        this.$refs.observer.setErrors({
          court: ["Unable to get case filed by, contact admin"],
        });
      })
    },
    load_data() {
      this.get_lawyer()
      this.get_districts()
      this.get_court_types()
      this.get_causes()
      this.get_filed_by()
    }
  },
  watch: {
    court_type: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (newValue && this.dialog) {
          this.get_courts(newValue)
          this.get_purposes(newValue)
          if (newValue !== oldValue) {
            this.item.court = null
            this.item.purpose = null
          }
        }
      }
    }
  },
  mounted() {

  }
}
</script>

<style>
div.v-list-item__content > div.v-list-item__title {
  overflow-wrap: break-word;
}
</style>
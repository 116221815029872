<template>
  <ReviewPetitionDc :parent="parent" :seq="seq" :flow="flow">
    <template v-slot:review_petition_dc_child="{parent,seq, flow}">
      <WritContainer :parent="parent" :seq="seq" :flow="flow" class="my-1">

      </WritContainer>
    </template>
  </ReviewPetitionDc>
</template>

<script>

import ReviewPetitionDc
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/review_petition_dc/ReviewPetitionDc";
import WritContainer
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/execution/sub_components/WritContainer";

export default {
  name: "Flow6",
  props: ['parent', 'seq', 'flow'],
  components: {ReviewPetitionDc, WritContainer}
}
</script>

<style scoped>

</style>
<template>
  <v-expansion-panels class="elevation-9 rounded-xl ma-2 pa-0">
    <v-expansion-panel class="ma-0 pa-0">
      <v-expansion-panel-header color="orange" expand-icon="mdi-file-tree" class="elevation-9 ma-0"
                                @click="load_child_execution">
          <span class="font-weight-bold">
            Execution Mamla
          </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="amber lighten-4 ma-0 pa-0">
        <v-skeleton-loader
            type="table-heading, list-item-two-line,table-tfoot"
            class="mx-auto"
            v-bind="attrs"
            v-if="loader"
        >
        </v-skeleton-loader>
        <v-row class="my-2">
          <v-col cols="auto" align="center" justify="center">
            <AddChildExecutionForm v-if="add_button" activator_name="Add New Child Execution Mamla"
                                   form_title="Add New"
                                   :url="urls.add_url"
                                   :file="this.parent.file.id"
                                   :parent="parent.id"
                                   seq="2"
                                   :flow="flow"
                                   :execution_parent="parent.id"
                                   activator_icon="mdi-plus" action="add"
                                   tooltip_text="Add New"
                                   big_btn="true" color="orange" @done_event="child_execution_added"
                                   snackbar_text="New Child Execution Mamla is added successfully">
            </AddChildExecutionForm>
          </v-col>
          <v-col cols="6" align="center" justify="center">
            <AddBlankChildExecutionForm v-if="add_blank_button" @done_event="child_execution_added"
                                        form_title="Add New Blank Child"
                                        :file="this.parent.file.id" :parent="parent.id"
                                        dialogue_text="Are you sure you, you want to create a blank entry?"
                                        :url="urls.blank_url"
                                        seq="2"
                                        :flow="flow"
                                        :execution_parent="parent.id"
                                        activator_name="Create a blank Child Execution Mamla"
                                        activator_icon="mdi-file-hidden"
                                        tooltip_text="Blank Child Execution Mamla"
                                        snackbar_text="Blank Child Execution case has been created"
                                        action="blank"
                                        big_btn="true" color="orange" :small=false>
            </AddBlankChildExecutionForm>
          </v-col>
        </v-row>
        <v-expansion-panels class="elevation-9 rounded-xl ma-0 pa-0">
          <v-expansion-panel class="my-1 pa-0" v-for="item in info" :key="item.id">
            <v-expansion-panel-header color="orange" expand-icon="mdi-file-tree" class="elevation-9 ma-0">
            <span class="font-weight-bold">
                Execution Mamla - {{ item.execution_no }}
            </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="amber lighten-4 ma-0 pa-0">
              <ChildExecution :parent="parent" :seq="seq" :flow="flow" :item="item"
                              @delete_signal="child_execution_deleted" @add_signal="child_execution_added"/>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import ChildExecution
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/child_execuion_mamla/ChildExecution";
import child_execution_mamla_urls from "@/constants/mamla/loan_mamla/child_execution_mamla";
import AddChildExecutionForm
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/child_execuion_mamla/sub_components/AddChildExecutionForm";
import AddBlankChildExecutionForm
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/child_execuion_mamla/sub_components/AddBlankChildExecutionForm";
import axios from "axios";
import child_execution_mamla_perms from "@/constants/permissions/child_execution_mamla_perms";

export default {
  name: "ChildExecutionContainer",
  props: ['parent', 'seq', 'flow'],
  components: {ChildExecution, AddChildExecutionForm, AddBlankChildExecutionForm},
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
    add_button: function () {
      return this.$store.getters.permissionCheckers(child_execution_mamla_perms.add) && this.show_buttons
    },
    add_blank_button: function () {
      return this.$store.getters.permissionCheckers(child_execution_mamla_perms.blank) && this.show_buttons
    },
  },
  data: () => ({
    loaded: false,
    loader: true,
    urls: {
      get_by_parent: child_execution_mamla_urls.get_by_parent,
      add_url: child_execution_mamla_urls.add,
      blank_url: child_execution_mamla_urls.blank
    },
    attrs: {
      class: 'mb-6',
      boilerplate: false,
      elevation: 2,
    },
    show_buttons: false,
    info: null
  }),
  methods: {
    load_child_execution() {
      if (this.loaded) {
        this.loaded = false
      } else {
        this.axios_conf
        let config = {
          headers: {
            multi: 'true'
          }
        }
        // console.log('ajax url: ', this.urls.get_by_parent.replace('{main_parent}', this.parent.id))
        axios.get(this.urls.get_by_parent.replace('{main_parent}', this.parent.id), config).then((resp) => {
          this.$store.commit('setJWT', resp.data.key)
          this.info = resp.data.items
          this.loaded = true
          this.loader = false
          if (this.info.length < 1) {
            this.show_buttons = true
          } else {
            this.show_buttons = false
          }
        }).catch((error) => {
          this.$store.commit('setJWT', error.response.data.key)
          this.loaded = true
          this.info = null
        })
      }

    },
    execution_updated(value) {
      this.loader = false
      this.info = value
      this.$emit('update_signal', true)
    },
    child_execution_added() {
      this.info = []
      this.loaded = false
      this.loader = true
      this.load_child_execution()
    },
    child_execution_deleted() {
      console.log('Delete signal thrown')
      this.info = []
      this.loaded = false
      this.loader = true
      this.load_child_execution()
    }

  },
  // beforeMount() {
  //   this.load_child_execution()
  // }
}
</script>

<style scoped>

</style>
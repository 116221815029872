<template>
  <v-row justify="center" class="my-3">
    <v-col cols="auto" color="orange" align-self="center" class="mx-1">
      <delete_dialog v-if="delete_button" :passed_item="info"
                     :dialogue_text="`Are you sure you want to Delete the Case?`"
                     :url="`${urls.delete_url.replace('{id}', info.id)}`"
                     activator_name="Delete Case" activator_icon="mdi-delete"
                     tooltip_text="Delete"
                     snackbar_text="Review Petition (DC) Case has been deleted"
                     action="delete"
                     big_btn="true" color="orange" :small=true
                     @done_event="delete_data"
      ></delete_dialog>
    </v-col>
    <v-col cols="auto" align-self="center" class="mx-1">
      <ChangeExecutionLawyerForm v-if="change_lawyer_button" activator_name="Change Lawyer Info"
                                 form_title="Change Lawyer Information"
                                 :url="urls.change_lawyer.replace('{case_type}', '2')"
                                 :passed_item="info"
                                 activator_icon="mdi-account-switch" action="add"
                                 tooltip_text="Change Lawyer"
                                 big_btn="true" color="orange" @done_event="update_data"
                                 snackbar_text="Lawyer Information Updated Successfully"
                                 :small=true>
      </ChangeExecutionLawyerForm>
    </v-col>
    <v-col cols="auto" align-self="center" class="mx-1">
      <ChangeExecutionCaseForm v-if="change_case_info_button" activator_name="Change Case Info"
                               form_title="Change Case Information"
                               :url="urls.case_info.replace('{case_type}', '2')"
                               :passed_item="info"
                               activator_icon="mdi-file-key" action="add"
                               tooltip_text="Change Case Info"
                               big_btn="true" color="orange" @done_event="update_data"
                               snackbar_text="Case Information Updated Successfully"
                               :small=true>
      </ChangeExecutionCaseForm>
    </v-col>
    <v-col cols="auto" align-self="center" class="mx-1">
      <ChangeExecutionCourtForm v-if="change_court_info_button" activator_name="Change Court Info"
                                form_title="Change Court Information"
                                :url="urls.court_info.replace('{case_type}', '2')"
                                :passed_item="info"
                                activator_icon="mdi-office-building-marker-outline" action="add"
                                tooltip_text="Change Court Info"
                                big_btn="true" color="orange" @done_event="update_data"
                                snackbar_text="Case Court Information Updated Successfully"
                                :small=true>
      </ChangeExecutionCourtForm>
    </v-col>
    <v-col cols="auto" align-self="center" class="mx-1">
      <ChangeExecutionNextDateForm v-if="change_next_date_button" activator_name="Change Next Date"
                                   form_title="Change Next Date"
                                   :url="urls.next_date.replace('{case_type}', '2')"
                                   :passed_item="info"
                                   activator_icon="mdi-calendar-edit" action="add"
                                   tooltip_text="Change Next Date"
                                   big_btn="true" color="orange" @done_event="update_data"
                                   snackbar_text="Case Next Date Updated Successfully"
                                   :small=true>
      </ChangeExecutionNextDateForm>
    </v-col>
    <v-col cols="auto" align-self="center" class="mx-1">
      <DisposeExecutionForm v-if="dispose_button" activator_name="Dispose Mamla"
                            form_title="Dispose Execution"
                            :url="urls.dispose" :file="this.$route.params.fileid"
                            :mamla="info.id"
                            :passed_item="info"
                            activator_icon="mdi-close-box" action="add"
                            tooltip_text="Dispose this mamla"
                            big_btn="true" color="orange" @done_event="update_data"
                            snackbar_text="Execution Case Disposed"
                            :small=true>
      </DisposeExecutionForm>
    </v-col>
  </v-row>
</template>

<script>
import review_petition_dc_mamla_perms from "@/constants/permissions/review_petition_dc_perms";
import review_petition_dc_mamla_urls from "@/constants/mamla/loan_mamla/review_petition_dc";
import common_info from "@/constants/mamla/common_info";
import delete_dialog
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/execution/sub_components/delete_dialog";
import ChangeExecutionLawyerForm
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/execution/sub_components/ChangeExecutionLawyerForm";
import ChangeExecutionCaseForm
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/execution/sub_components/ChangeExecutionCaseForm";
import ChangeExecutionCourtForm
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/execution/sub_components/ChangeExecutionCourtForm";
import ChangeExecutionNextDateForm
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/execution/sub_components/ChangeExecutionNextDateForm";
import DisposeExecutionForm
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/execution/sub_components/DisposeExecutionForm";

export default {
  name: "Actions",
  props: ['info'],
  components: {
    delete_dialog,
    ChangeExecutionLawyerForm,
    ChangeExecutionCaseForm,
    ChangeExecutionCourtForm,
    ChangeExecutionNextDateForm,
    DisposeExecutionForm
  },
  computed: {
    delete_button: function () {
      return this.$store.getters.permissionCheckers(review_petition_dc_mamla_perms.delete) && !this.info.dispose_status
    },
    change_lawyer_button: function () {
      return this.$store.getters.permissionCheckers('mamla_container.change_lawyer') && this.info
    },
    change_case_info_button: function () {
      return this.$store.getters.permissionCheckers('mamla_container.change_case_info') && this.info
    },
    change_court_info_button: function () {
      return this.$store.getters.permissionCheckers('mamla_container.change_court') && this.info
    },
    change_next_date_button: function () {
      return this.$store.getters.permissionCheckers('mamla_container.change_next_date') && this.info
    },
    dispose_button() {
      return this.$store.getters.permissionCheckers(review_petition_dc_mamla_perms.dispose) && !this.info.dispose_status
    }
  },
  data: () => ({
    urls: {
      dispose: review_petition_dc_mamla_urls.disposal,
      delete_url: review_petition_dc_mamla_urls.single,
      change_lawyer: common_info.lawyer.chnage,
      court_info: common_info.court_info.chnage,
      case_info: common_info.case_info.chnage,
      next_date: common_info.next_date.chnage
    }
  }),
  methods: {
    update_data(value) {
      this.$emit('update_event', value)
    },
    delete_data() {
      this.$emit('delete_event', true)
    }
  }
}
</script>

<style scoped>

</style>
const criminal_review_petition_hc_urls = {
    get_by_file: "mamla/loanmamla/criminal_review_petition_hc_by_file/{fileid}/{seq}/",
    get_by_parent: "mamla/loanmamla/criminal_review_petition_hc_by_parent/{parent}/{seq}/",
    list: "mamla/loanmamla/criminal_review_petition_hc/",
    blank: "mamla/loanmamla/criminal_review_petition_hc_blank/",
    disposal: "mamla/loanmamla/criminal_review_petition_hc_disposal/",
    single: "mamla/loanmamla/criminal_review_petition_hc/{id}/",
    add: "mamla/loanmamla/criminal_review_petition_hc/"
}

export default criminal_review_petition_hc_urls;
<template>
  <CriminalAppealHc :parent="parent" :seq="seq" :flow="flow">
    <template v-slot:criminal_appeal_hc_child="{parent, seq , flow}">
      <CrplaAdContainer :parent="parent" :seq="seq" :flow="flow" cpla="true" class="my-1">
      </CrplaAdContainer>
    </template>
  </CriminalAppealHc>
</template>

<script>
import CriminalAppealHc
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/criminal_appeal_hc/CriminalAppealHc";
import CrplaAdContainer from "@/components/mamla/loan_mamla/loan_mamla_sub_components/crpla/CrplaAdContainer";

export default {
  name: "Flow2",
  props: ['parent', 'seq', 'flow'],
  components: {CriminalAppealHc, CrplaAdContainer}
}
</script>

<style scoped>

</style>
<template>
  <v-container class="ma-0 pa-0">
    <v-skeleton-loader
        type="table-heading, list-item-two-line,table-tfoot"
        class="mx-auto"
        v-bind="attrs"
        v-if="loader"
    >
    </v-skeleton-loader>
    <v-container v-if="!loader" class="pa-1 ma-0">
      <v-row align="center" justify="center" class="my-3" v-if="view_buttons">
        <v-col cols="6" align="center" justify="center">
          <AddExecutionForm v-if="add_button" activator_name="Add New Execution Mamla"
                            form_title="Add New"
                            :url="urls.add_url" :file="this.parent.file.id"
                            :parent="parent.id"
                            seq="2"
                            :flow="flow"
                            activator_icon="mdi-plus" action="add"
                            tooltip_text="Add New"
                            big_btn="true" color="orange" @done_event="execution_updated"
                            snackbar_text="New Execution Mamla is added successfully">
          </AddExecutionForm>
        </v-col>
        <v-col cols="6" align="center" justify="center">
          <AddBlankExecutionForm v-if="add_blank_button" @done_event="execution_updated"
                                 :file="this.parent.file.id" :parent="parent.id"
                                 dialogue_text="Are you sure you, you want to create a blank entry?"
                                 :url="urls.blank_url"
                                 seq="2"
                                 :flow="flow"
                                 activator_name="Create a blank Execution Mamla"
                                 activator_icon="mdi-file-hidden"
                                 tooltip_text="Blank Execution Mamla"
                                 snackbar_text="Blank Execution case has been created"
                                 action="blank"
                                 big_btn="true" color="orange" :small=false>
          </AddBlankExecutionForm>
        </v-col>
      </v-row>
      <v-container v-if="!view_buttons" class="pa-0 ma-0">
        <ExecutionDetails :info="info"/>
        <Actions :info="info" @update_event="update_event_handler" @delete_event="delete_event_hanlder"/>
        <ChildExecutionContainer :parent="info" :seq="2" :flow="flow"></ChildExecutionContainer>
        <HistoryViews :info="info"/>
        <WritContainer v-if="view_writ_container" :parent="info" :seq="3" :flow="flow" class="my-2 pa-0"/>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import axios from "axios";
import execution_mamla_urls from "@/constants/mamla/loan_mamla/execution_mamla";
import AddBlankExecutionForm
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/execution/sub_components/AddBlankExecutionForm";
import AddExecutionForm
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/execution/sub_components/AddExecutionForm";
import ExecutionDetails
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/execution/sub_components/ExecutionDetails";
import Actions from "@/components/mamla/loan_mamla/loan_mamla_sub_components/execution/sub_components/Actions";
import HistoryViews from "@/components/mamla/loan_mamla/common_components/HistoryViews";
import WritContainer
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/execution/sub_components/WritContainer";
import ChildExecutionContainer
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/child_execuion_mamla/ChildExecutionContainer";

export default {
  name: "Execution",
  props: ['parent', 'flow'],
  components: {
    WritContainer,
    AddBlankExecutionForm, AddExecutionForm, ExecutionDetails, Actions, HistoryViews, ChildExecutionContainer
  },
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
    view_buttons() {
      return !this.loader && !this.info
    },
    add_button: function () {
      return this.$store.getters.permissionCheckers('mamla_container.add_execution') && !this.loader && !this.info
    },
    add_blank_button: function () {
      return this.$store.getters.permissionCheckers('mamla_container.blank_execution') && !this.loader && !this.info
    },
    view_details() {
      return !this.loader && this.info
    },
    view_writ_container: function () {
      return this.$store.getters.permissionCheckers('mamla_container.view_writ') && !this.loader && this.info
    },
  },
  data: () => ({
    urls: {
      get_url: execution_mamla_urls.get_by_parent,
      blank_url: execution_mamla_urls.blank,
      add_url: execution_mamla_urls.add,
    },
    attrs: {
      class: 'mb-6',
      boilerplate: false,
      elevation: 2,
    },
    loader: true,
    info: {},
  }),
  methods: {
    get_info() {
      this.loader = true
      this.axios_conf
      let config = {
        headers: {
          multi: 'true'
        }
      }
      axios.get(this.urls.get_url.replace('{parent}', this.parent.file.id).replace('{seq}', '2'), config).then((resp) => {
        this.$store.commit('setJWT', resp.data.key)
        this.info = resp.data.items[0]
        this.loader = false
      }).catch((error) => {
        this.$store.commit('setJWT', error.response.data.key)
        this.loader = false
        this.info = {}
      })
    },
    execution_updated(value) {
      this.loader = false
      this.info = value
      this.$emit('update_signal', true)
    },
    update_event_handler(value) {
      this.info = value
    }
    ,
    delete_event_hanlder() {
      this.get_info()
    }
  },
  mounted() {
    this.get_info()
  },

}
</script>

<style scoped>

</style>
<template>
  <v-dialog
      max-width="70%"
      v-model="dialog"
      @input="load_data"
  >

    <template v-slot:activator="{ on:dialog, attrs }">
      <v-btn v-if="big_btn" v-bind="attrs" v-on="{...dialog}" :loading="loading" :color="color" :small="small">
        <v-icon>
          {{ activator_icon }}
        </v-icon>
        {{ activator_name }}
      </v-btn>
      <v-tooltip bottom v-if="!big_btn">
        <template v-slot:activator="{on:tooltip}">
          <v-icon v-if="!big_btn" v-bind="attrs" small
                  v-on="{...dialog,...tooltip}" class="mx-4">
            {{ activator_icon }}
          </v-icon>
        </template>
        <span v-if="!big_btn">{{ tooltip_text }}</span>
      </v-tooltip>
    </template>

    <ValidationObserver ref="observer" v-slot="{ handleSubmit}">
      <v-form ref="vform" @submit.prevent="handleSubmit(handle_submit)">
        <v-card class="light-blue lighten-3">
          <v-card-title>
            <span class="text-h5">{{ form_title }}</span>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="non_field" name="None Field"
                                    rules=""
                                    v-slot="{ errors }">
                  <ul v-for="err in errors" :key="err">
                    <li>
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="4"
              >
                <ValidationProvider mode="eager" vid="cause" name="Cause"
                                    rules="objectNotEmpty:cause|required"
                                    v-slot="{ errors }">
                  <v-autocomplete
                      label="Cause*"
                      hint="Case of the case"
                      :items="causes"
                      :item-text="(item)=>item.cause"
                      item-value="code"
                      v-model="item.cause"
                      requiredkl
                      autocomplete="nope"
                      :loading="causes_loading"
                      dense
                  >
                  </v-autocomplete>
                  <ul>
                    <li v-for="err in errors" :key="err">
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="4"
              >
                <ValidationProvider mode="eager" vid="case_filed_by" name="Case Filed by"
                                    rules="objectNotEmpty:case_filed_by|required"
                                    v-slot="{ errors }">
                  <v-autocomplete
                      label="Case Filed By*"
                      hint="Whomever filed the case"
                      :items="filed_by"
                      :item-text="(item)=>item.name"
                      item-value="code"
                      v-model="item.case_filed_by"
                      requiredkl
                      autocomplete="nope"
                      :loading="filed_by_loading"
                      dense
                  >
                  </v-autocomplete>
                  <ul>
                    <li v-for="err in errors" :key="err">
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="4"
              >
                <ValidationProvider mode="eager" vid="case_number" name="Case Number"
                                    rules="required"
                                    v-slot="{ errors }">
                  <v-text-field
                      label="Case Number*"
                      hint="Case Number for the mamla"
                      required
                      autocomplete="nope"
                      v-model="item.case_number"
                      maxlength="256"
                      counter
                      dense
                  ></v-text-field>
                  <ul>
                    <li v-for="err in errors" :key="err">
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="4"
              >

                <v-menu
                    v-model="filing_date_menu"
                    :nudge-right="40"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    dense
                >
                  <template v-slot:activator="{ on, attrs }">
                    <ValidationProvider mode="eager" vid="filing_date" name="Filing Date"
                                        rules="required"
                                        v-slot="{ errors }">
                      <v-text-field
                          v-model="item.filing_date"
                          label="Filing Date*"
                          hint="Date on which case is filed on the court"
                          prepend-icon="mdi-calendar"
                          readonly
                          required
                          v-bind="attrs"
                          v-on="on"
                          dense
                      ></v-text-field>
                      <ul>
                        <li v-for="err in errors" :key="err">
                          <span class="red--text">{{ err }}</span>
                        </li>
                      </ul>
                    </ValidationProvider>
                  </template>
                  <v-date-picker
                      v-model="item.filing_date"
                      @input="filing_date_menu=false"
                      :max="max_date"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                  cols="4"
              >
                <ValidationProvider mode="eager" vid="claimed_amnt" name="Claimed Amount"
                                    rules="required|min_value:1"
                                    v-slot="{ errors }">
                  <v-text-field
                      label="Claimed Amount*"
                      hint="Claimed Amount for this mamla"
                      required
                      autocomplete="nope"
                      v-model="item.claimed_amnt"
                      maxlength="32"
                      counter
                      clearable
                      dense
                      type="number"
                  ></v-text-field>
                  <ul>
                    <li v-for="err in errors" :key="err">
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="4"
              >
                <ValidationProvider mode="eager" vid="district" name="District"
                                    rules="objectNotEmpty:district|required"
                                    v-slot="{ errors }">
                  <v-autocomplete
                      label="District*"
                      hint="District"
                      :items="districts"
                      :item-text="(item)=>item.name"
                      item-value="id"
                      v-model="item.district"
                      requiredkl
                      autocomplete="nope"
                      :loading="district_loading"
                      dense
                  >
                  </v-autocomplete>
                  <ul>
                    <li v-for="err in errors" :key="err">
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
            </v-row>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
            >
              Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                type="submit"
                :loading="submit_loading"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import axios from "axios";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import "@/validations/office_validations"
import app_setting_api from "@/constants/app_setting/app_setting_api";
import parsing_functions from "@/constants/util_functions/parsing_functions";
import {user_urls} from "@/constants/user_urls";

export default {
  name: "ChangeArthoRinCaseForm",
  props: ['icon', 'activator_name', 'activator_icon', 'form_title', 'action', 'big_btn', 'url', 'snackbar_text', 'tooltip_text', 'loading', 'color', 'small', 'file', 'file_conn', 'passed_item'],
  components: {ValidationProvider, ValidationObserver},
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
    max_date: function () {
      let today = new Date()
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = today.getFullYear();
      return String(yyyy + '-' + mm + '-' + dd)
    }
  },
  data: () => ({
    submit_loading: false,
    dialog: false,
    filing_date_menu: false,
    item: {
      // case_number: null,
      // filing_date: null,
      // district: null,
      // claimed_amnt: null,
    },
    districts: [],
    district_loading: false,
    non_field_errors: [],
    causes: [],
    causes_loading: false,
    filed_by: [],
    filed_by_loading: false,
  }),
  methods: {
    async add_new_item() {
      this.axios_conf
      let conf = {
        headers: {
          multi: true
        }
      }
      let data = {
        id: this.passed_item.id,
        case_number: this.item.case_number,
        filing_date: this.item.filing_date,
        district: this.item.district,
        claimed_amnt: this.item.claimed_amnt,
        cause: this.item.cause,
        case_filed_by: this.item.case_filed_by,
      }
      await axios.patch(this.url, data, conf)
          .then((resp) => {
            this.$store.commit('setJWT', resp.data.key)
            this.dialog = false
            this.$refs.vform.resetValidation()
            // this.$refs.vform.reset()
            this.$emit('done_event', resp.data.items)
            this.$store.commit("update_snackbar", {
              text: this.snackbar_text.replace('{value}', data.case_no),
              status: true
            })
          })
          .catch((error) => {
            // console.log(error.response.data)
            if (error.response.data) {
              this.$store.commit('setJWT', error.response.data.key)
              // console.log(error.response.data.key)
              this.$refs.observer.setErrors({
                case_number: error.response.data.errors.case_number,
                filing_date: error.response.data.errors.filing_date,
                claimed_amnt: error.response.data.errors.claimed_amnt,
                lawyer: error.response.data.errors.lawyer,
                lawyer_engage_date: error.response.data.errors.lawyer_engage_date,
                next_date: error.response.data.errors.next_date,
                purpose: error.response.data.errors.purpose,
                purpose_remarks: error.response.data.errors.purpose_remarks,
                district: error.response.data.errors.district,
                court: error.response.data.errors.court,
                court_no: error.response.data.errors.court_no,
                non_field: error.response.data.errors.non_field,
              });
            }
          })
      this.submit_loading = false
    },
    handle_submit() {
      this.submit_loading = true
      this.add_new_item()
    },
    get_districts() {
      this.axios_conf
      let conf = {
        headers: {multi: true}
      }
      this.district_loading = true
      axios.get(app_setting_api.district.list, conf).then((resp) => {
        this.$store.commit('setJWT', resp.data.key)
        this.districts = resp.data.items
        this.district_loading = false
      }).catch((err) => {
        if (err.response) {
          this.$store.commit('setJWT', err.response.data.key)
        }
        this.$store.commit("update_snackbar", {text: "Unable to get district data", status: true})
        this.$refs.observer.setErrors({
          district: ["Unable to get district data, contact admin"],
        });
      })
    },
    get_causes() {
      this.axios_conf
      let conf = {
        headers: {multi: true}
      }
      this.causes_loading = true
      axios.get(app_setting_api.cause.list, conf).then((resp) => {
        this.$store.commit('setJWT', resp.data.key)
        this.causes = resp.data.items
        this.causes_loading = false
      }).catch((err) => {
        if (err.response) {
          if (err.response.status >= 400) {
            this.axios_conf
            axios.post(user_urls.user)
            this.$router.replace({name: 'Login'})
            this.$store.commit('setLogout')
            return 0
          }
          this.$store.commit('setJWT', err.response.data.key)
        }
        this.$store.commit("update_snackbar", {text: "Unable to get cause data", status: true})
        this.$refs.observer.setErrors({
          court: ["Unable to get Cause data, contact admin"],
        });
      })
    },
    get_filed_by() {
      this.axios_conf
      let conf = {
        headers: {multi: true}
      }
      this.filed_by_loading = true
      axios.get(app_setting_api.case_filed_by.list, conf).then((resp) => {
        this.$store.commit('setJWT', resp.data.key)
        this.filed_by = resp.data.items
        this.filed_by_loading = false
      }).catch((err) => {
        if (err.response) {
          if (err.response.status >= 400) {
            this.axios_conf
            axios.post(user_urls.user)
            this.$router.replace({name: 'Login'})
            this.$store.commit('setLogout')
            return 0
          }
          this.$store.commit('setJWT', err.response.data.key)
        }
        this.$store.commit("update_snackbar", {text: "Unable to get case filed by data", status: true})
        this.$refs.observer.setErrors({
          court: ["Unable to get case filed by, contact admin"],
        });
      })
    },
    load_data() {
      this.get_districts()
      this.get_causes()
      this.get_filed_by()
    }
  },
  watch: {
    passed_item: {
      immediate: true,
      deep: true,
      handler(newValue) {
        let items = JSON.parse(JSON.stringify(newValue))
        this.item.case_number = items.case_number
        if (items.filing_date) {
          this.item.filing_date = parsing_functions.parse_date_to_standard(items.filing_date)
        } else {
          this.item.filing_date = null
        }
        this.item.district = items.district ? items.district.id : null
        this.item.claimed_amnt = items.claimed_amnt
        this.item.case_filed_by = items.case_filed_by ? items.case_filed_by.code : null
        this.item.cause = items.cause ? items.cause.code : null
        // console.log(items)
      }
    }
  },
  mounted() {

  }
}
</script>

<style>
div.v-list-item__content > div.v-list-item__title {
  overflow-wrap: break-word;
}
</style>
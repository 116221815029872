<template>
  <v-expansion-panels class="elevation-9 rounded-xl ma-0 pa-0">
    <v-expansion-panel>
      <v-expansion-panel-header color="orange" expand-icon="mdi-file-tree" class="elevation-9">
          <span class="font-weight-bold">
            Second Step
          </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="amber lighten-4 ma-0 pa-0">
        <v-card class="my-3 elevation-4 ma-0 pa-0">
          <v-tabs
              v-model="tab"
              background-color="orange"
              color="light-green accent-4"
              centered
              icons-and-text
              dense
              show-arrows
              class="ma-0 pa-0"
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab v-if="execution">
              <span class="black--text">Artha Rin Execution</span>
              <v-icon class="black--text">mdi-file-tree</v-icon>
            </v-tab>
            <v-tab v-if="artho_appeal_dc">
              <span class="black--text">Appeal (DC)</span>
              <v-icon class="black--text">mdi-file-tree</v-icon>
            </v-tab>
            <v-tab v-if="first_appeal_hc">
              <span class="black--text">Appeal (HC)</span>
              <v-icon class="black--text">mdi-file-tree</v-icon>
            </v-tab>
            <!--            <v-tab>-->
            <!--              <span class="black&#45;&#45;text">Writ</span>-->
            <!--              <v-icon class="black&#45;&#45;text">mdi-file-tree</v-icon>-->
            <!--            </v-tab>-->
            <v-tab v-if="flow5">
              <span class="black--text">Civil Revision (DC)</span>
              <v-icon class="black--text">mdi-file-tree</v-icon>
            </v-tab>
            <v-tab v-if="flow6">
              <span class="black--text">Review Petition (DC)</span>
              <v-icon class="black--text">mdi-file-tree</v-icon>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" class="amber lighten-4 ma-0 pa-0">
            <v-tab-item v-if="execution">
              <execution :parent="parent" flow="1"/>
            </v-tab-item>
            <v-tab-item v-if="artho_appeal_dc">
              <div>
                <ArthoApealDc :parent="parent" :seq="2" flow="2"/>
              </div>
            </v-tab-item>
            <v-tab-item v-if="first_appeal_hc">
              <Flow3 :parent="parent" :seq="2" flow="3"/>
            </v-tab-item>
            <!--            <v-tab-item>-->
            <!--              <Writ :parent="parent" seq="2" class="pa-1" flow="4"/>-->
            <!--            </v-tab-item>-->
            <v-tab-item v-if="flow5">
              <Flow5 :parent="parent" seq="2" class="pa-1" flow="5"/>
            </v-tab-item>
            <v-tab-item v-if="flow6">
              <Flow6 :parent="parent" :seq="2" class="pa-1" flow="6"/>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import Execution from "@/components/mamla/loan_mamla/loan_mamla_sub_components/execution/Execution";
// import Writ from "@/components/mamla/loan_mamla/loan_mamla_sub_components/writ/Writ";
import ArthoApealDc from "@/components/mamla/ArthoRinFlows/Artho_rin_flow_2/ArthoApealDc";
import first_apeal_hc_mamla_perms from "@/constants/permissions/first_apeal_hc_perms";
import artho_apeal_dc_mamla_perms from "@/constants/permissions/artho_apeal_dc_perms";
import Flow3 from "@/components/mamla/ArthoRinFlows/artho_rin_flow_3/Flow3";
import Flow5 from "@/components/mamla/ArthoRinFlows/artho_rin_flow_5/Flow5";
import civil_rv_dc_mamla_perms from "@/constants/permissions/civil_rv_dc_perms";
import review_petition_dc_mamla_perms from "@/constants/permissions/review_petition_dc_perms";
import Flow6 from "@/components/mamla/ArthoRinFlows/artho_rin_flow_6/Flow6";

export default {
  name: "SecStep",
  props: ['parent'],
  components: {
    Flow3, Execution,
    // Writ,
    ArthoApealDc, Flow5, Flow6
  },
  computed: {
    execution() {
      return this.$store.getters.permissionCheckers('mamla_container.view_execution')
    },
    writ() {
      return this.$store.getters.permissionCheckers('mamla_container.view_writ')
    },
    artho_appeal_dc() {
      return this.$store.getters.permissionCheckers(artho_apeal_dc_mamla_perms.view)
    },

    first_appeal_hc() {
      return this.$store.getters.permissionCheckers(first_apeal_hc_mamla_perms.view)
    },
    flow5() {
      return this.$store.getters.permissionCheckers(civil_rv_dc_mamla_perms.view)
    },
    flow6() {
      return this.$store.getters.permissionCheckers(review_petition_dc_mamla_perms.view)
    }
  },
  data: () => ({
    tab: null,
  })
}
</script>

<style scoped>

</style>
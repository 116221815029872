<template>
  <CriminalReviewPetitionHc :parent="parent" :seq="seq" :flow="flow">
  </CriminalReviewPetitionHc>
</template>

<script>
import CriminalReviewPetitionHc
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/criminal_review_petition_hc/CriminalReviewPetitionHc";

export default {
  name: "Flow5",
  props: ['parent', 'seq', 'flow'],
  components: {CriminalReviewPetitionHc}
}
</script>

<style scoped>

</style>
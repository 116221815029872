<template>
  <v-container v-if="has_view_permission" class="px-0 ma-0" style="font-max-size: small">
    <CommonInformation/>
    <ArthoRin/>
    <CrSessionContainer/>
    <CrSessionOthersContainer class="my-2"/>
    <bankruptcy-container class="my-2"></bankruptcy-container>
    <WritContainer class="my-2"></WritContainer>
    <div style="height: 500px; width: 100%; opacity: 100%; color: red" class="spacer"></div>
  </v-container>
</template>

<script>


import axios from "axios";
import CommonInformation from "@/components/mamla/loan_mamla/loan_mamla_sub_components/CommonInformation";
import ArthoRin from "@/components/mamla/loan_mamla/loan_mamla_sub_components/artho_rin/ArthoRin";
import CrSessionContainer from "@/components/mamla/loan_mamla/loan_mamla_sub_components/cr_session/CrSessionContainer";
import CrSessionOthersContainer
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/cr_session_others/CrSessionOthersContainer";
import BankruptcyContainer
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/bankruptcy/BankruptcyContainer";
import WritContainer
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/writ_as_different_flows/WritContainer";
// import loan_mamla_urls from "@/constants/mamla/loan_mamla/loan_mamla";

export default {
  name: "LoanMamlaDetails",
  components: {
    CommonInformation,
    ArthoRin,
    CrSessionContainer,
    CrSessionOthersContainer,
    BankruptcyContainer,
    WritContainer
  },
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
    has_view_permission() {
      return this.$store.getters.permissionCheckers('mamla_container.view_loanmamlafile')
    },
  },
  data: () => ({
    file_conn: {},
    mamla_conn: {},
  }),
  methods: {},
  mounted() {
    // this.get_file_conn();
  },
  watch: {}
}
</script>

<style>
div[name="subjects_table"] > div > table > tbody > tr:hover {
  background: #82B1FF !important;
}
</style>
<template>
  <v-expansion-panels class="elevation-9 rounded-xl ma-0 pa-0">
    <v-expansion-panel>
      <v-expansion-panel-header color="orange" expand-icon="mdi-file-tree" class="elevation-9">
          <span class="font-weight-bold">
            Second Step
          </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="amber lighten-4 ma-0 pa-0">
        <v-card class="my-3 elevation-4 ma-0 pa-0">
          <v-tabs
              v-model="tab"
              background-color="orange"
              color="light-green accent-4"
              centered
              icons-and-text
              dense
              show-arrows
              class="ma-0 pa-0"
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab v-if="flow1">
              <span class="black--text">Criminal Appeal (DC)</span>
              <v-icon class="black--text">mdi-file-tree</v-icon>
            </v-tab>
            <v-tab v-if="flow2">
              <span class="black--text">Criminal Appeal (HC)</span>
              <v-icon class="black--text">mdi-file-tree</v-icon>
            </v-tab>
            <!--            <v-tab v-if="flow3">-->
            <!--              <span class="black&#45;&#45;text">Writ</span>-->
            <!--              <v-icon class="black&#45;&#45;text">mdi-file-tree</v-icon>-->
            <!--            </v-tab>-->
            <v-tab v-if="flow4">
              <span class="black--text">Criminal Mis Case(HC)</span>
              <v-icon class="black--text">mdi-file-tree</v-icon>
            </v-tab>
            <v-tab v-if="flow5">
              <span class="black--text">Criminal Review Petition(HC)</span>
              <v-icon class="black--text">mdi-file-tree</v-icon>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" class="amber lighten-4 ma-0 pa-0">
            <v-tab-item v-if="flow1">
              <Flow1 :parent="parent" :seq="seq" flow="1"/>
            </v-tab-item>
            <v-tab-item v-if="flow2">
              <Flow2 :parent="parent" :seq="seq" flow="2"/>
            </v-tab-item>
            <!--            <v-tab-item v-if="flow3">-->
            <!--              <Flow3 :parent="parent" :seq="seq" flow="3"/>-->
            <!--            </v-tab-item>-->
            <v-tab-item v-if="flow4">
              <Flow4 :parent="parent" :seq="seq" flow="4"/>
            </v-tab-item>
            <v-tab-item v-if="flow5">
              <Flow5 :parent="parent" :seq="seq" flow="5"/>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import Flow1 from "@/components/mamla/CrSessionFlows/Flow1";
import Flow2 from "@/components/mamla/CrSessionFlows/Flow2";
// import Flow3 from "@/components/mamla/CrSessionFlows/Flow3";
import Flow4 from "@/components/mamla/CrSessionFlows/Flow4";
import Flow5 from "@/components/mamla/CrSessionFlows/Flow5";

import criminal_appeal_dc_mamla_perms from "@/constants/permissions/criminal_appeal_dc_perms";
import civil_appeal_ad_mamla_perms from "@/constants/permissions/civil_appeal_ad_perms";
import writ_mamla_perms from "@/constants/permissions/writ_perms";
import criminal_misc_case_hc_mamla_perms from "@/constants/permissions/criminal_misc_case_hc_perms";
import criminal_review_petition_hc_perms from "@/constants/permissions/criminal_review_petition_hc_perms";

export default {
  name: "SecStep",
  props: ['parent', 'seq'],
  components: {
    Flow1, Flow2,
    // Flow3,
    Flow4, Flow5
  },
  computed: {
    flow1() {
      return this.$store.getters.permissionCheckers(criminal_appeal_dc_mamla_perms.view)
    },
    flow2() {
      return this.$store.getters.permissionCheckers(civil_appeal_ad_mamla_perms.view)
    },
    flow3() {
      return this.$store.getters.permissionCheckers(writ_mamla_perms.view)
    },
    flow4() {
      return this.$store.getters.permissionCheckers(criminal_misc_case_hc_mamla_perms.view)
    },
    flow5() {
      return this.$store.getters.permissionCheckers(criminal_review_petition_hc_perms.view)
    },
  },
  data: () => ({
    tab: null,
  })
}
</script>

<style scoped>

</style>
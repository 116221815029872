<template>
  <v-dialog
      max-width="70%"
      v-model="dialog"
      @input="load_data"
  >

    <template v-slot:activator="{ on:dialog, attrs }">
      <v-btn v-if="big_btn" v-bind="attrs" v-on="{...dialog}" :loading="loading" :color="color" :small="small">
        <v-icon>
          {{ activator_icon }}
        </v-icon>
        {{ activator_name }}
      </v-btn>
      <v-tooltip bottom v-if="!big_btn">
        <template v-slot:activator="{on:tooltip}">
          <v-icon v-if="!big_btn" v-bind="attrs" small
                  v-on="{...dialog,...tooltip}" class="mx-4">
            {{ activator_icon }}
          </v-icon>
        </template>
        <span v-if="!big_btn">{{ tooltip_text }}</span>
      </v-tooltip>
    </template>

    <ValidationObserver ref="observer" v-slot="{ handleSubmit}">
      <v-form ref="vform" @submit.prevent="handleSubmit(handle_submit)">
        <v-card class="light-blue lighten-3">
          <v-card-title>
            <span class="text-h5">{{ form_title }}</span>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="non_field" name="None Field"
                                    rules=""
                                    v-slot="{ errors }">
                  <ul v-for="err in errors" :key="err">
                    <li>
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="4"
              >
                <ValidationProvider mode="eager" vid="cheque_no" name="Cheque No"
                                    rules="required"
                                    v-slot="{ errors }">
                  <v-text-field
                      label="Cheque No*"
                      hint="Cheque No"
                      required
                      autocomplete="nope"
                      v-model="item.cheque_no"
                      maxlength="256"
                      counter
                      dense
                  ></v-text-field>
                  <ul>
                    <li v-for="err in errors" :key="err">
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="4"
              >
                <v-menu
                    v-model="cheque_dishon_date_menu"
                    :nudge-right="40"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    dense
                >
                  <template v-slot:activator="{ on, attrs }">
                    <ValidationProvider mode="eager" vid="cheque_dishon_date" name="Cheque Dishonour Date"
                                        rules="required"
                                        v-slot="{ errors }">
                      <v-text-field
                          v-model="item.cheque_dishon_date"
                          label="Cheque Dishonour date*"
                          hint="Date on which cheque was dishonoured"
                          prepend-icon="mdi-calendar"
                          readonly
                          required
                          v-bind="attrs"
                          v-on="on"
                          dense
                      ></v-text-field>
                      <ul>
                        <li v-for="err in errors" :key="err">
                          <span class="red--text">{{ err }}</span>
                        </li>
                      </ul>
                    </ValidationProvider>
                  </template>
                  <v-date-picker
                      v-model="item.cheque_dishon_date"
                      @input="cheque_dishon_date_menu=false"
                      :max="max_date"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                  cols="4"
              >
                <v-menu
                    v-model="cr_session_legal_notice_date_menu"
                    :nudge-right="40"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    dense
                >
                  <template v-slot:activator="{ on, attrs }">
                    <ValidationProvider mode="eager" vid="cr_session_legal_notice_date" name="Legal Notice Date"
                                        rules="required"
                                        v-slot="{ errors }">
                      <v-text-field
                          v-model="item.cr_session_legal_notice_date"
                          label="Legal Notice date*"
                          hint="Legal Notice Date"
                          prepend-icon="mdi-calendar"
                          readonly
                          required
                          v-bind="attrs"
                          v-on="on"
                          dense
                      ></v-text-field>
                      <ul>
                        <li v-for="err in errors" :key="err">
                          <span class="red--text">{{ err }}</span>
                        </li>
                      </ul>
                    </ValidationProvider>
                  </template>
                  <v-date-picker
                      v-model="item.cr_session_legal_notice_date"
                      @input="cr_session_legal_notice_date_menu=false"
                      :max="max_date"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
            >
              Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                type="submit"
                :loading="submit_loading"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import axios from "axios";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import "@/validations/office_validations"
import parsing_functions from "@/constants/util_functions/parsing_functions";

export default {
  name: "ChangeChequeInfoForm",
  props: ['icon', 'activator_name', 'activator_icon', 'form_title', 'action', 'big_btn', 'url', 'snackbar_text', 'tooltip_text', 'loading', 'color', 'small', 'file', 'file_conn', 'passed_item'],
  components: {ValidationProvider, ValidationObserver},
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
    max_date: function () {
      let today = new Date()
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = today.getFullYear();
      return String(yyyy + '-' + mm + '-' + dd)
    }
  },
  data: () => ({
    submit_loading: false,
    dialog: false,
    cheque_dishon_date_menu: false,
    cr_session_legal_notice_date_menu: false,
    item: {},
    yes_no_drop: [
      {
        text: "Yes",
        value: true
      },
      {
        text: "No",
        value: false
      },
    ]
  }),
  methods: {
    async add_new_item() {
      this.axios_conf
      let conf = {
        headers: {
          multi: true
        }
      }
      let data = {
        id: this.passed_item.id,
        cheque_no: this.item.cheque_no,
        cheque_dishon_date: this.item.cheque_dishon_date,
        cr_session_legal_notice_date: this.item.cr_session_legal_notice_date
      }
      // console.log(this.url)
      await axios.patch(this.url, data, conf)
          .then((resp) => {
            this.$store.commit('setJWT', resp.data.key)
            this.dialog = false
            this.$refs.vform.resetValidation()
            // this.$refs.vform.reset()
            this.$emit('done_event', resp.data.items)
            this.$store.commit("update_snackbar", {
              text: this.snackbar_text,
              status: true
            })
          })
          .catch((error) => {
            // console.log(error.response.data)
            if (error.response.data) {
              this.$store.commit('setJWT', error.response.data.key)
              // console.log(error.response.data.key)
              this.$refs.observer.setErrors({
                cheque_no: error.response.data.errors.cheque_no,
                cheque_dishon_date: error.response.data.errors.cheque_dishon_date,
                cr_session_legal_notice_date: error.response.data.errors.cr_session_legal_notice_date,
                non_field: error.response.data.errors.non_field_errors,
              });
            }
            // console.log(error)
          })
      this.submit_loading = false
    },
    handle_submit() {
      this.submit_loading = true
      this.add_new_item()
    },
    load_data() {
    }
  },
  watch: {
    passed_item: {
      immediate: true,
      deep: true,
      handler(newValue) {
        let items = JSON.parse(JSON.stringify(newValue))
        this.item.id = items.id
        this.item.cheque_no = items.cheque_no
        if (items.cheque_dishon_date) {
          this.item.cheque_dishon_date = parsing_functions.parse_date_to_standard(items.cheque_dishon_date)
        } else {
          this.item.cheque_dishon_date = null
        }
        if (items.cr_session_legal_notice_date) {
          this.item.cr_session_legal_notice_date = parsing_functions.parse_date_to_standard(items.cr_session_legal_notice_date)
        } else {
          this.item.cr_session_legal_notice_date = null
        }
        // console.log(this.item)
      }
    }
  },
  mounted() {

  }
}
</script>

<style>
div.v-list-item__content > div.v-list-item__title {
  overflow-wrap: break-word;
}
</style>
<template>
  <loan-mamla-details/>
</template>

<script>
import LoanMamlaDetails from "@/components/mamla/loan_mamla/loan_mamla_details"

export default {
  name: "AddLoanMamla",
  components: {LoanMamlaDetails},
  methods: {},
  mounted: () => {
    document.title = 'Agrani Bank Legal Suit System | Loan Mamla Details'
    window.scrollTo(0, 0);
  }
}
</script>

<style scoped>

</style>
<template>
  <v-dialog
      max-width="70%"
      v-model="dialog"
      @input="load_data"
  >

    <template v-slot:activator="{ on:dialog, attrs }">
      <v-btn v-if="big_btn" v-bind="attrs" v-on="{...dialog}" :loading="loading" :color="color" :small="small">
        <v-icon>
          {{ activator_icon }}
        </v-icon>
        {{ activator_name }}
      </v-btn>
      <v-tooltip bottom v-if="!big_btn">
        <template v-slot:activator="{on:tooltip}">
          <v-icon v-if="!big_btn" v-bind="attrs" small
                  v-on="{...dialog,...tooltip}" class="mx-4">
            {{ activator_icon }}
          </v-icon>
        </template>
        <span v-if="!big_btn">{{ tooltip_text }}</span>
      </v-tooltip>
    </template>

    <ValidationObserver ref="observer" v-slot="{ handleSubmit}">
      <v-form ref="vform" @submit.prevent="handleSubmit(handle_submit)">
        <v-card class="light-blue lighten-3">
          <v-card-title>
            <span class="text-h5">{{ form_title }}</span>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="non_field" name="None Field"
                                    rules=""
                                    v-slot="{ errors }">
                  <ul v-for="err in errors" :key="err">
                    <li>
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="4"
              >
                <ValidationProvider mode="eager" vid="court_type" name="Court Type"
                                    rules="objectNotEmpty:court_type|required"
                                    v-slot="{ errors }">
                  <v-autocomplete
                      label="Court Type*"
                      hint="Search by court type name"
                      :items="court_types"
                      :item-text="(item)=>item.name"
                      item-value="id"
                      v-model="court_type"
                      required
                      autocomplete="nope"
                      :loading="court_types_loading"
                      dense
                  >
                  </v-autocomplete>
                  <ul>
                    <li v-for="err in errors" :key="err">
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="4"
              >
                <ValidationProvider mode="eager" vid="court" name="Court"
                                    rules="objectNotEmpty:court|required"
                                    v-slot="{ errors }">
                  <v-autocomplete
                      label="Court*"
                      hint="Search by court name"
                      :items="courts"
                      :item-text="(item)=>item.name"
                      item-value="id"
                      v-model="item.court"
                      required
                      autocomplete="nope"
                      :loading="courts_loading"
                      dense

                  >
                  </v-autocomplete>
                  <ul>
                    <li v-for="err in errors" :key="err">
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="4"
              >
                <ValidationProvider mode="eager" vid="court_no" name="Case No"
                                    rules="required"
                                    v-slot="{ errors }">
                  <v-text-field
                      label="Court No*"
                      hint="Court No"
                      required
                      autocomplete="nope"
                      v-model="item.court_no"
                      maxlength="256"
                      counter
                      dense
                      type="number"
                  ></v-text-field>
                  <ul>
                    <li v-for="err in errors" :key="err">
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
            </v-row>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
            >
              Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                type="submit"
                :loading="submit_loading"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import axios from "axios";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import "@/validations/office_validations"
import app_setting_api from "@/constants/app_setting/app_setting_api";
import {user_urls} from "@/constants/user_urls";

export default {
  name: "ChangeArthorinCourtForm",
  props: ['icon', 'activator_name', 'activator_icon', 'form_title', 'action', 'big_btn', 'url', 'snackbar_text', 'tooltip_text', 'loading', 'color', 'small', 'file', 'file_conn', 'passed_item'],
  components: {ValidationProvider, ValidationObserver},
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
  },
  data: () => ({
    submit_loading: false,
    dialog: false,
    courts_loading: false,
    court_types_loading: false,
    courts: [],
    court_types: [],
    court_type: null,
    item: {
      court: null,
      court_no: null,
    },
    non_field_errors: [],
  }),
  methods: {
    async add_new_item() {
      this.axios_conf
      let conf = {
        headers: {
          multi: true
        }
      }
      // console.log('item#', this.item)
      let data = {
        id: this.passed_item.id,
        ...this.item
      }
      await axios.patch(this.url, data, conf)
          .then((resp) => {
            this.$store.commit('setJWT', resp.data.key)
            this.dialog = false
            this.$refs.vform.resetValidation()
            // this.$refs.vform.reset()
            this.$emit('done_event', resp.data.items)
            this.$store.commit("update_snackbar", {
              text: this.snackbar_text.replace('{value}', data.case_no),
              status: true
            })
          })
          .catch((error) => {
            // console.log(error.response.data)
            if (error.response.data) {
              this.$store.commit('setJWT', error.response.data.key)
              // console.log(error.response.data.key)
              this.$refs.observer.setErrors({
                case_number: error.response.data.errors.case_number,
                filing_date: error.response.data.errors.filing_date,
                claimed_amnt: error.response.data.errors.claimed_amnt,
                lawyer: error.response.data.errors.lawyer,
                lawyer_engage_date: error.response.data.errors.lawyer_engage_date,
                next_date: error.response.data.errors.next_date,
                purpose: error.response.data.errors.purpose,
                purpose_remarks: error.response.data.errors.purpose_remarks,
                district: error.response.data.errors.district,
                court: error.response.data.errors.court,
                court_no: error.response.data.errors.court_no,
                non_field: error.response.data.errors.non_field_errors,
              });
            }
          })
      this.submit_loading = false
    },
    handle_submit() {
      this.submit_loading = true
      this.add_new_item()
    },
    get_court_types() {
      this.axios_conf
      let conf = {
        headers: {multi: true}
      }
      this.court_types_loading = true
      axios.get(app_setting_api.court_type.list, conf).then((resp) => {
        this.$store.commit('setJWT', resp.data.key)
        this.court_types = resp.data.items
        this.court_types_loading = false
      }).catch((err) => {
        if (err.response) {
          if (err.response.status >= 400) {
            this.axios_conf
            axios.post(user_urls.user)
            this.$router.replace({name: 'Login'})
            this.$store.commit('setLogout')
            return 0
          }
          this.$store.commit('setJWT', err.response.data.key)
        }
        this.$store.commit("update_snackbar", {text: "Unable to get Court data", status: true})
        this.$refs.observer.setErrors({
          court: ["Unable to get Court Type data, contact admin"],
        });
      })
    },
    get_courts(courtType) {
      this.axios_conf
      let conf = {
        headers: {multi: true}
      }
      this.courts = []
      this.courts_loading = true
      axios.get(app_setting_api.court.list, conf).then((resp) => {
        this.$store.commit('setJWT', resp.data.key)
        this.courts = resp.data.items.filter(value => value.type.id === courtType)
        this.courts_loading = false
      }).catch((err) => {
        if (err.response) {
          if (err.response.status >= 400) {
            this.axios_conf
            axios.post(user_urls.user)
            this.$router.replace({name: 'Login'})
            this.$store.commit('setLogout')
            return 0
          }
          this.$store.commit('setJWT', err.response.data.key)
        }
        this.$store.commit("update_snackbar", {text: "Unable to get Court data", status: true})
        this.$refs.observer.setErrors({
          court: ["Unable to get Court data, contact admin"],
        });
      })
    },
    load_data() {
      this.get_court_types()
    }
  },
  watch: {
    passed_item: {
      immediate: true,
      deep: true,
      handler(newValue) {
        let passed = JSON.parse(JSON.stringify(newValue))
        this.item.court = passed.court ? passed.court.id : null
        this.item.court_no = passed.court_no ? passed.court_no : null
        this.court_type = passed.court ? passed.court.type.id : null
        this.get_courts(this.court_type)
        // console.log(this.item)
      }
    },
    court_type: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        // console.log(this.dialog)
        if (newValue && this.dialog) {
          this.get_courts(newValue)
          if (newValue !== oldValue) {
            this.item.court = null
          }
          // console.log('newvalue:', newValue, 'oldValue:', oldValue)
        }
      }
    }
  },
  mounted() {

  }
}
</script>

<style>
div.v-list-item__content > div.v-list-item__title {
  overflow-wrap: break-word;
}
</style>
const civil_rv_dc_mamla_urls = {
    get_by_file: "mamla/loanmamla/civil_rv_dc_by_file/{fileid}/{seq}/",
    get_by_parent: "mamla/loanmamla/civil_rv_dc_by_parent/{parent}/{seq}/",
    list: "mamla/loanmamla/civil_rv_dc/",
    blank: "mamla/loanmamla/civil_rv_dc_blank/",
    disposal: "mamla/loanmamla/civil_rv_dc_disposal/",
    single: "mamla/loanmamla/civil_rv_dc/{id}/",
    add: "mamla/loanmamla/civil_rv_dc/"
}

export default civil_rv_dc_mamla_urls;
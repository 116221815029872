<template>
  <v-expansion-panels class="elevation-9 rounded-xl ma-0 pa-0">
    <v-expansion-panel class="ma-0 pa-0">
      <v-expansion-panel-header color="orange" expand-icon="mdi-file-tree" class="elevation-9 ma-0">
          <span class="font-weight-bold">
            Criminal Revision (HC)
          </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="amber lighten-4 ma-0 pa-0">
        <CriminalRevisionHc :parent="parent" :seq="seq" :flow="flow">
          <template v-slot:criminal_revision_hc_child="{parent, seq, flow}">
            <CrplaAdContainer :parent="parent" :seq="seq" :flow="flow" v-if="crpla" class="my-1"/>
          </template>
        </CriminalRevisionHc>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import CriminalRevisionHc
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/criminal_revision_hc/CriminalRevisionHc";
import CrplaAdContainer from "@/components/mamla/loan_mamla/loan_mamla_sub_components/crpla/CrplaAdContainer";

export default {
  name: "CriminalRevisionHcContainer",
  props: ['parent', 'seq', 'crpla', 'flow'],
  components: {CrplaAdContainer, CriminalRevisionHc}
}
</script>

<style scoped>

</style>
<template>
  <v-expansion-panels class="elevation-9 rounded-xl ma-0 pa-0">
    <v-expansion-panel class="ma-0 pa-0" @click="cr_session_clicked=!cr_session_clicked">
      <v-expansion-panel-header color="amber accent-1" expand-icon="mdi-cash-multiple"
                                class="elevation-9 ma-0">
          <span class="text-h6">
            CR Session Mamla
          </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="ma-0 pa-0" color="amber lighten-4">
        <CrSession :seq="1" :clicked="cr_session_clicked"/>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import CrSession from "@/components/mamla/loan_mamla/loan_mamla_sub_components/cr_session/CrSession";

export default {
  name: "CrSessionContainer",
  props: ['seq'],
  components: {CrSession},
  data: () => ({
    cr_session_clicked: false
  })
}
</script>

<style scoped>

</style>
const execution_mamla_urls = {
    get_by_file: "mamla/loanmamla/execution_by_file/{fileid}/{seq}/",
    get_by_parent: "mamla/loanmamla/execution_by_file/{parent}/{seq}/",
    list: "mamla/loanmamla/execution/",
    blank: "mamla/loanmamla/execution_blank/",
    disposal: "mamla/loanmamla/execution_disposal/",
    single: "mamla/loanmamla/execution/{id}/",
    add: "mamla/loanmamla/execution/"
}

export default execution_mamla_urls;
<template>
  <v-container class="ma-0 pa-0">
    <v-row justify="center" class="my-3" dense>
      <recovery-transaction-details
          :mamla="mamla"
          :file="mamla_obj.file.id"
          :mamla_obj="mamla_obj"
      />
    </v-row>
    <v-row justify="center" class="my-3" dense>
      <expense-transaction-details
          :mamla="mamla"
          :file="mamla_obj.file.id"
          :mamla_obj="mamla_obj"
      />
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import recoveryTransactionDetails
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/artho_rin/sub_components/recovery/recoveryTransactionDetails";
import expenseTransactionDetails
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/artho_rin/sub_components/expense/expenseTransactionDetails";

export default {
  name: "RecoveryExpense",
  props: ['mamla', 'file', 'mamla_obj'],
  components: {recoveryTransactionDetails, expenseTransactionDetails},
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
  },
  mounted() {
  },
  watch: {}
}
</script>

<style scoped>

</style>
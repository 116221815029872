const review_petition_dc_mamla_urls = {
    get_by_file: "mamla/loanmamla/review_petition_dc_by_file/{fileid}/{seq}/",
    get_by_parent: "mamla/loanmamla/review_petition_dc_by_parent/{parent}/{seq}/",
    list: "mamla/loanmamla/review_petition_dc/",
    blank: "mamla/loanmamla/review_petition_dc_blank/",
    disposal: "mamla/loanmamla/review_petition_dc_disposal/",
    single: "mamla/loanmamla/review_petition_dc/{id}/",
    add: "mamla/loanmamla/review_petition_dc/"
}

export default review_petition_dc_mamla_urls;
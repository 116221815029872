const civil_rv_mamla_urls = {
    get_by_file: "mamla/loanmamla/civil_rv_by_file/{fileid}/{seq}/",
    get_by_parent: "mamla/loanmamla/civil_rv_by_parent/{parent}/{seq}/",
    list: "mamla/loanmamla/civil_rv/",
    blank: "mamla/loanmamla/civil_rv_blank/",
    disposal: "mamla/loanmamla/civil_rv_disposal/",
    single: "mamla/loanmamla/civil_rv/{id}/",
    add: "mamla/loanmamla/civil_rv/"
}

export default civil_rv_mamla_urls;
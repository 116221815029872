const crpla_mamla_urls = {
    get_by_file: "mamla/loanmamla/crpla_ad_by_file/{fileid}/{seq}/",
    get_by_parent: "mamla/loanmamla/crpla_ad_by_parent/{parent}/{seq}/",
    list: "mamla/loanmamla/crpla_ad/",
    blank: "mamla/loanmamla/crpla_ad_blank/",
    disposal: "mamla/loanmamla/crpla_ad_disposal/",
    single: "mamla/loanmamla/crpla_ad/{id}/",
    add: "mamla/loanmamla/crpla_ad/"
}

export default crpla_mamla_urls;
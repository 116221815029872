const child_execution_mamla_urls = {
    get_by_file: "mamla/loanmamla/child_execution_by_file/{fileid}/",
    get_by_parent: "mamla/loanmamla/child_execution_by_parent/{main_parent}/",
    get_by_exe_parent: "mamla/loanmamla/child_execution_by_parent/{main_parent}/{exe_parent}/",
    list: "mamla/loanmamla/child_execution_by_parent/{main_parent}/",
    blank: "mamla/loanmamla/child_execution_blank/",
    disposal: "mamla/loanmamla/artho_apeal_dc_disposal/",
    single: "mamla/loanmamla/child_execution/{id}/",
    add: "mamla/loanmamla/child_execution/"
}

export default child_execution_mamla_urls;
<template>
  <v-container v-if="has_view_permission" class="my-2 pa-0" style="min-width: 100%">
    <v-expansion-panels class="elevation-9 rounded-xl ma-0 pa-0">
      <v-expansion-panel class="ma-0 pa-0">
        <v-expansion-panel-header
            class="font-weight-bold"
            color="amber accent-1"
            expand-icon="mdi-cash-multiple"
            @click="expanded"
        >
          <span class="text-h6">Artho Rin Mamla</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content
            color="amber lighten-4"
            class="ma-0 pa-0"
        >
          <v-skeleton-loader
              type="table-heading, list-item-two-line,table-tfoot"
              class="mx-auto"
              v-bind="attrs"
              v-if="loader"
          >
          </v-skeleton-loader>
          <v-row align="center" justify="center">
            <v-col cols="6" align="center" justify="center">
              <AddArthorinForm v-if="add_button" activator_name="Add New"
                               form_title="Add Artho Rin"
                               :url="add_url" :file="this.$route.params.fileid"
                               activator_icon="mdi-plus" action="add"
                               tooltip_text="Add New"
                               big_btn="true" color="orange" @done_event="artho_rin_added"
                               snackbar_text="New Artho Rin Mamla is added successfully">
              </AddArthorinForm>
            </v-col>
            <v-col cols="6" align="center" justify="center">
              <AddBlankArthorinForm v-if="add_blank_button" @done_event="artho_rin_added"
                                    :file_conn="file_conn" :file="this.$route.params.fileid"
                                    dialogue_text="Are you sure you, you want to create a blank artho rin entry?"
                                    :url="blank_url"
                                    activator_name="Create a blank Artho rin entry"
                                    activator_icon="mdi-file-hidden"
                                    tooltip_text="Blank Artho rin"
                                    snackbar_text="Blank Artho rin case#{value} has been created"
                                    action="blank"
                                    big_btn="true" color="orange" :small=false>
              </AddBlankArthorinForm>
            </v-col>
          </v-row>
          <v-container v-if="view_details" class="ma-0 pa-0">
            <v-row justify="center">
              <v-col cols="3" class="text-left">
                <span class="font-weight-bold">Case Number</span>
              </v-col>
              <v-col cols="3" class="text-right">
                <span class="font-weight-bold">{{ info.case_number }}</span>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="3" class="text-left">
                <span class="font-weight-bold">Filing Date</span>
              </v-col>
              <v-col cols="3" class="text-right">
                <span>{{ info.filing_date }}</span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row justify="center">
              <v-col cols="3" class="text-left">
                <span class="font-weight-bold">Claimed Amount</span>
              </v-col>
              <v-col cols="3" class="text-right">
                <span>{{ info.claimed_amnt }}</span>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="3" class="text-left">
                <span class="font-weight-bold">District</span>
              </v-col>
              <v-col cols="3" class="text-right">
                <span>{{ info.district ? info.district.name : null }}</span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row justify="center">
              <v-col cols="3" class="text-left">
                <span class="font-weight-bold">Court Name</span>
              </v-col>
              <v-col cols="3" class="text-right">
                <span>{{ info.court ? info.court.name : null }}</span>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="3" class="text-left">
                <span class="font-weight-bold">Court No</span>
              </v-col>
              <v-col cols="3" class="text-right">
                <span>{{ info.court_no }}</span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row justify="center">
              <v-col cols="3" class="text-left">
                <span class="font-weight-bold">Lawyer</span>
              </v-col>
              <v-col cols="3" class="text-right">
                <span>{{ info.lawyer ? info.lawyer.name : null }}</span>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="3" class="text-left">
                <span class="font-weight-bold">Engage Date</span>
              </v-col>
              <v-col cols="3" class="text-right">
                <span>{{ info.lawyer_engage_date }}</span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row justify="center">
              <v-col cols="3" class="text-left">
                <span class="font-weight-bold">Next Date</span>
              </v-col>
              <v-col cols="3" class="text-right">
                <span>{{ info.next_date }}</span>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="3" class="text-left">
                <span class="font-weight-bold">Purpose</span>
              </v-col>
              <v-col cols="3" class="text-right">
                <span>{{ info.purpose ? info.purpose.name : null }}</span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row justify="center">
              <v-col cols="3" class="text-left">
                <span class="font-weight-bold">Purpose Remarks</span>
              </v-col>
              <v-col cols="3" style="overflow-wrap: break-word;" class="text-right">
                <span>{{ info.purpose_remarks }}</span>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="3" class="text-left">
                <span class="font-weight-bold">Disposed?</span>
              </v-col>
              <v-col cols="3" class="text-right">
                <span class="font-weight-bold red--text">{{ info.dispose_status ? 'Disposed' : 'Pending' }}</span>
              </v-col>
            </v-row>
            <v-divider/>
            <v-row justify="center">
              <v-col cols="3" class="text-left">
                <span class="font-weight-bold">Disposal Date</span>
              </v-col>
              <v-col cols="3" class="text-right">
                <span>{{ info.disposal_date }}</span>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="3" class="text-left">
                <span class="font-weight-bold">Disposal Through</span>
              </v-col>
              <v-col cols="3" class="text-right">
                <span>{{ info.disposed_through ? info.disposed_through.name : null }}</span>
              </v-col>
            </v-row>
            <v-divider/>
            <v-row justify="center">
              <v-col cols="3" class="text-left">
                <span class="font-weight-bold">Disposal Nature</span>
              </v-col>
              <v-col cols="3" class="text-right">
                <span>{{ info.disposal_nature ? info.disposal_nature.name : null }}</span>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="3" class="text-left">
                <span class="font-weight-bold">Disposal Result</span>
              </v-col>
              <v-col cols="3" class="text-right">
                <span>{{ info.disposal_result ? info.disposal_result.name : null }}</span>
              </v-col>
            </v-row>
            <v-divider/>
            <v-row justify="center">
              <v-col cols="3" class="text-left">
                <span class="font-weight-bold">Disposal Remarks</span>
              </v-col>
              <v-col cols="3" class="text-right">
                <span>{{ info.disposal_remarks }}</span>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="3" class="text-left">
                <span class="font-weight-bold">Disposing Period</span>
              </v-col>
              <v-col cols="3" class="text-right">
                <span>{{ info.disposing_period ? info.disposing_period.name : null }}</span>
              </v-col>
            </v-row>
            <v-divider></v-divider>

            <v-row justify="center">
              <v-col cols="3" class="text-left">
                <span class="font-weight-bold">Input Timestamp</span>
              </v-col>
              <v-col cols="3" class="text-right">
                <span>{{ info.timestamp }}</span>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="3" class="text-left">
                <span class="font-weight-bold">Input By</span>
              </v-col>
              <v-col cols="3" class="text-right">
                <span>{{ info.user.username }}</span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row justify="center">
              <v-col cols="3" class="text-left">
                <span class="font-weight-bold">Last updated on</span>
              </v-col>
              <v-col cols="3" class="text-right">
                <span>{{ info.update_timestamp }}</span>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="3" class="text-left">
                <span class="font-weight-bold">Updated By</span>
              </v-col>
              <v-col cols="3" class="text-right">
                <span>{{ computed_username }}</span>
              </v-col>
            </v-row>
            <v-row></v-row>
            <v-row justify="center" class="my-3">
              <v-col cols="auto" color="orange" align-self="center" class="mx-1">
                <delete_dialog v-if="delete_button" @form_status="update_page" :passed_item="info"
                               :dialogue_text="`Are you sure you want to Delete Arthorin Case?`"
                               :url="`${delete_url.replace('{mamlaid}', info.id)}`"
                               activator_name="Delete Case" activator_icon="mdi-delete"
                               tooltip_text="Delete"
                               snackbar_text="Artho Rin Case has been deleted"
                               action="delete"
                               big_btn="true" color="orange" :small=true
                ></delete_dialog>
              </v-col>
              <v-col cols="auto" align-self="center" class="mx-1">
                <ChangeArthorinLawyerForm v-if="change_lawyer_button" activator_name="Change Lawyer Info"
                                          form_title="Change Lawyer Information"
                                          :url="change_lawyer" :file="this.$route.params.fileid"
                                          :passed_item="info"
                                          activator_icon="mdi-account-switch" action="add"
                                          tooltip_text="Change Lawyer"
                                          big_btn="true" color="orange" @done_event="artho_rin_added"
                                          snackbar_text="Lawyer Information Updated Successfully"
                                          :small=true>
                </ChangeArthorinLawyerForm>
              </v-col>
              <v-col cols="auto" align-self="center" class="mx-1">
                <ChangeArthorinCaseForm v-if="change_case_info_button" activator_name="Change Case Info"
                                        form_title="Change Case Information"
                                        :url="case_info" :file="this.$route.params.fileid"
                                        :passed_item="info"
                                        activator_icon="mdi-file-key" action="add"
                                        tooltip_text="Change Case Info"
                                        big_btn="true" color="orange" @done_event="artho_rin_added"
                                        snackbar_text="Case Information Updated Successfully"
                                        :small=true>
                </ChangeArthorinCaseForm>
              </v-col>
              <v-col cols="auto" align-self="center" class="mx-1">
                <ChangeArthorinCourtForm v-if="change_court_info_button" activator_name="Change Court Info"
                                         form_title="Change Court Information"
                                         :url="court_info" :file="this.$route.params.fileid"
                                         :passed_item="info"
                                         activator_icon="mdi-office-building-marker-outline" action="add"
                                         tooltip_text="Change Court Info"
                                         big_btn="true" color="orange" @done_event="artho_rin_added"
                                         snackbar_text="Case Court Information Updated Successfully"
                                         :small=true>
                </ChangeArthorinCourtForm>
              </v-col>
              <v-col cols="auto" align-self="center" class="mx-1">
                <ChangeArthorinNextDateForm v-if="change_next_date_button" activator_name="Change Next Date"
                                            form_title="Change Next Date"
                                            :url="next_date" :file="this.$route.params.fileid"
                                            :passed_item="info"
                                            activator_icon="mdi-calendar-edit" action="add"
                                            tooltip_text="Change Next Date"
                                            big_btn="true" color="orange" @done_event="artho_rin_added"
                                            snackbar_text="Case Next Date Updated Successfully"
                                            :small=true>
                </ChangeArthorinNextDateForm>
              </v-col>
              <v-col cols="auto" align-self="center" class="mx-1">
                <DisposeArthorinForm v-if="dispose_arthorin_button(info)" activator_name="Dispose Artho Rin"
                                     form_title="Dispose Artho Rin"
                                     :url="dispose" :file="this.$route.params.fileid"
                                     :mamla_conn="mamla_conn" :mamla="info.id"
                                     :passed_item="info"
                                     activator_icon="mdi-close-box" action="add"
                                     tooltip_text="Dispose this mamla"
                                     big_btn="true" color="orange" @done_event="artho_rin_added"
                                     snackbar_text="Artho rin Case Disposed"
                                     :small=true>
                </DisposeArthorinForm>
              </v-col>
            </v-row>
            <v-row></v-row>
            <RecoveryExpense
                :mamla_obj="info"
                :file="info.file.id"
                :mamla="info.id"
            />
            <v-row justify="center" class="my=3" dense>
              <LawyerHistory :passed_item="info"/>
            </v-row>
            <v-row justify="center" class="my=3" dense>
              <NextDayPurposeHistory :passed_item="info"/>
            </v-row>
            <v-row justify="center" class="my=3" dense v-if="second_step_expansion">
              <SecStep :parent="info"/>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import axios from "axios";
import loan_mamla_urls from "@/constants/mamla/loan_mamla/loan_mamla";
import AddArthorinForm from "@/components/mamla/loan_mamla/loan_mamla_sub_components/artho_rin/AddArthorinForm";
import AddBlankArthorinForm
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/artho_rin/AddBlankArthorinForm";
import delete_dialog from "@/components/mamla/loan_mamla/loan_mamla_sub_components/artho_rin/delete_dialog";
import ChangeArthorinLawyerForm
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/artho_rin/sub_components/ChangeArthorinLawyerForm";
import ChangeArthorinCaseForm
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/artho_rin/sub_components/ChangeArthorinCaseForm";
import ChangeArthorinNextDateForm
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/artho_rin/sub_components/ChangeArthorinNextDateForm";
import ChangeArthorinCourtForm
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/artho_rin/sub_components/ChangeArthorinCourtForm";
import LawyerHistory from "@/components/mamla/history_views/LawyerHistory";
import NextDayPurposeHistory from "@/components/mamla/history_views/NextDayPurposeHistory";
import DisposeArthorinForm
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/artho_rin/sub_components/DisposeArthorinForm";
import RecoveryExpense from "@/components/mamla/loan_mamla/loan_mamla_sub_components/artho_rin/Recovery&Expense";
import SecStep from "@/components/mamla/loan_mamla/loan_mamla_sub_components/artho_rin/sub_components/sec_step/SecStep"

export default {
  name: "ArthoRin",
  components: {
    NextDayPurposeHistory,
    ChangeArthorinNextDateForm,
    AddArthorinForm,
    delete_dialog,
    AddBlankArthorinForm,
    ChangeArthorinLawyerForm,
    ChangeArthorinCaseForm,
    ChangeArthorinCourtForm,
    LawyerHistory,
    DisposeArthorinForm,
    RecoveryExpense,
    SecStep
  },
  props: ["file_conn", "mamla_conn"],
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
    has_view_permission() {
      return this.$store.getters.permissionCheckers('mamla_container.view_arthorin')
    },
    add_button: function () {
      return this.$store.getters.permissionCheckers('mamla_container.add_arthorin') && !this.loader && !this.info
    },
    add_blank_button: function () {
      return this.$store.getters.permissionCheckers('mamla_container.blank_arthorin') && !this.loader && !this.info
    },
    delete_button: function () {
      return this.$store.getters.permissionCheckers('mamla_container.delete_arthorin') && !this.info.dispose_status
    },
    view_details: function () {
      return this.$store.getters.permissionCheckers('mamla_container.view_arthorin') && !this.loader && this.info
    },
    change_lawyer_button: function () {
      return this.$store.getters.permissionCheckers('mamla_container.change_arthorin_lawyer') && !this.loader && this.info
    },
    change_case_info_button: function () {
      return this.$store.getters.permissionCheckers('mamla_container.change_case_info') && !this.loader && this.info
    },
    change_court_info_button: function () {
      return this.$store.getters.permissionCheckers('mamla_container.change_arthorin_court') && !this.loader && this.info
    },
    change_next_date_button: function () {
      return this.$store.getters.permissionCheckers('mamla_container.change_arthorin_next_date') && !this.loader && this.info
    },
    computed_username() {
      if (this.info.update_by) {
        return this.info.update_by.username;
      }
      return null
    },
    second_step_expansion() {
      if (this.info) {
        return this.$store.getters.permissionCheckers('mamla_container.view_execution') && !this.loader
      } else {
        return false
      }
    }
  },
  data: () => ({
    get_url: loan_mamla_urls.artho_rin.single,
    add_url: loan_mamla_urls.artho_rin.add,
    delete_url: loan_mamla_urls.artho_rin.delete,
    blank_url: loan_mamla_urls.artho_rin.blank,
    change_lawyer: loan_mamla_urls.artho_rin.lawyer,
    case_info: loan_mamla_urls.artho_rin.case_info,
    next_date: loan_mamla_urls.artho_rin.next_date,
    court_info: loan_mamla_urls.artho_rin.court_info,
    dispose: loan_mamla_urls.artho_rin.dispose,
    loader: true,
    clicked: false,
    attrs: {
      class: 'mb-6',
      boilerplate: false,
      elevation: 2,
    },
    info: null,
  }),
  methods: {
    get_info() {
      this.axios_conf
      let config = {
        headers: {
          multi: 'true'
        }
      }
      this.loader = true
      axios.get(this.get_url.replace('{fileid}', this.$route.params.fileid), config).then((resp) => {
        // console.log(resp)
        this.$store.commit('setJWT', resp.data.key)
        this.info = resp.data.items[0]
        this.loader = false
      }).catch((error) => {
        this.$store.commit('setJWT', error.response.data.key)
        this.loader = false
        this.info = {}
      })
    },
    expanded() {
      this.clicked = !this.clicked
      if (this.clicked) {
        this.get_info()
      }
    },
    artho_rin_added(value) {
      this.loader = false
      this.info = value
      this.$emit('update_signal', true)
    },
    update_page() {
      this.get_info()
    },
    dispose_arthorin_button(item) {
      return this.$store.getters.permissionCheckers('mamla_container.dispose_arthorin') && !this.loader && this.info && !item.dispose_status
    }
  },
  mounted() {

  },
  watch: {}
}
</script>

<style scoped>

</style>